import {
  resetCompensationPaymentDialog,
  setCompensationPaymentDialog,
  setSelectedCompensationTransactionId,
} from 'src/redux/slices/transaction-compensation.slice';
import { setSelectedTransactionId } from 'src/redux/slices/transaction.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export default function useTransactionCompensationPaymentDialog() {
  const toggle = useSelector(
    (state) =>
      state.transactionCompensation.default.compensationPayment.dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  const open = ({
    transactionCompensationId,
  }: {
    transactionCompensationId: number;
  }) => {
    dispatch(setSelectedCompensationTransactionId(transactionCompensationId));
    dispatch(setCompensationPaymentDialog({ toggle: true }));
  };

  const close = () => {
    dispatch(setSelectedTransactionId(null));
    dispatch(resetCompensationPaymentDialog());
  };

  return { open, close, toggle };
}

import { PaginationRes } from 'src/@types/app-response';
import {
  ConversationType,
  OverviewTaskHistoryRequest,
  OverviewTaskHistoryResponse,
  SearchTaskHistoryRequest,
  SearchTaskHistoryResponse,
} from 'src/@types/conversation';
import { Pagination } from 'src/@types/pagination';
import { PrefixType } from 'src/constants/prefix';
import axiosInstance from 'src/utils/axios';
import {
  CONTACT_CONVERSATION_API,
  CONVERSATION_API,
} from '../constants/conversation.api.constant';

export enum IndustryServerType {
  INACTIVE = 'inactive',
  ACTIVE = 'active',
}

export const overviewTaskHistory = (
  request: OverviewTaskHistoryRequest,
): Promise<OverviewTaskHistoryResponse> =>
  axiosInstance.get(
    `${PrefixType.CONSULTANT}${CONVERSATION_API.OVERVIEW_TASK_HISTORY}`,
    {
      params: request,
    },
  );

export const searchTaskHistory = (
  request: SearchTaskHistoryRequest,
): Promise<PaginationRes<SearchTaskHistoryResponse>> =>
  axiosInstance.get(
    `${PrefixType.CONSULTANT}${CONVERSATION_API.SEARCH_TASK_HISTORY}`,
    {
      params: {
        ...request,
      },
    },
  );

export const getConversationsToday = ({
  limit,
  page,
  startTime
}: Pagination & {
  startTime?: string | Date
}): Promise<PaginationRes<ConversationType>> =>
  axiosInstance.get(
    `${CONTACT_CONVERSATION_API.ROOT}${CONTACT_CONVERSATION_API.CONVERSATIONS_TODAY}`,
    {
      params: {
        limit,
        page,
        startTime
      },
    },
  );

export const createRecordConversation = (data: {
  nameFile: string;
  bucketName: string;
  conversationId: number;
}): Promise<any> =>
  axiosInstance.post(
    `${CONTACT_CONVERSATION_API.ROOT}${CONTACT_CONVERSATION_API.RECORD}`,
    {
      ...data,
    },
  );

import { DailyProvider } from '@daily-co/daily-react';
import { Box, BoxProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import {
  CallConversation,
  ConversationType,
  ConversationTypeType,
} from 'src/@types/conversation';
import { FileData, FileType } from 'src/@types/file';
import { User, UserType } from 'src/@types/user';
import { customIcon } from 'src/assets';
import ContactStar from 'src/components/conversation/ContactStar';
import VoiceCall from 'src/components/conversation/VoiceCall';
import { S3 } from 'src/config';
import { AuthContext } from 'src/contexts/JWTContext';
import { SocketContext } from 'src/contexts/SocketContext';
import {
  addMsg,
  deleteChat,
  setIsCall,
  setIsNewMsg,
  setOpenChat,
  updateConversation,
} from 'src/redux/slices/conversations.slice';
import { useDispatch, useSelector } from 'src/redux/store';
import { getDateNow, momentVi } from 'src/utils/date-handle';
import { handleUploadFileToS3Promise } from 'src/utils/s3-handle-file';
import ContactButton from '../../../../components/conversation/ContactButton';
import ConversationCall from '../../../../components/conversation/ConversationCall';
import CloseContactConfirm from './CloseContactConfirm';
import ContactChatContainer from './ContactChatContainer';
import ContactChatForm from './ContactChatForm';
import ContactMessages from './ContactMessages';

interface ContactConversationProps extends BoxProps {
  conversation: ConversationType;
  isNewMsg: boolean;
  itemKey: number;
  call: CallConversation;
}

export default function ContactConversation({
  conversation,
  isNewMsg,
  itemKey,
  call,
  ...other
}: ContactConversationProps) {
  const authData = useContext(AuthContext);
  const { contSocket } = useContext(SocketContext);

  const [openCloseConfirm, setOpenCloseConfirm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // redux
  const dispatch = useDispatch();
  const { chats } = useSelector((state) => state.conversations);
  const { open } = chats[itemKey];

  const handleCloseChat = () => {
    if (contSocket) {
      contSocket.emit('leave-room-contact', {
        conversationId: conversation.id,
      });
    }

    dispatch(
      deleteChat(
        chats.filter((item) => item?.conversation?.id !== conversation.id) ||
          [],
      ),
    );
  };

  const handleCloseWithMinus = () => {
    dispatch(
      setOpenChat({
        id: conversation.id,
        open: false,
      }),
    );
    if (contSocket) {
      contSocket.emit('consultant-leave-room-contact', {
        conversationId: conversation.id,
      });
      if (call?.isCall) {
        handleCloseCall();
      }
      dispatch(
        updateConversation({
          id: conversation.id,
          conversation: {
            endTime: getDateNow().toISOString(),
            rating: 0,
          },
        }),
      );
      dispatch(
        deleteChat(
          chats.filter((item) => item?.conversation?.id !== conversation.id) ||
            [],
        ),
      );
    }
  };

  const onSubmitFormMessage = (data: { content: string }) => {
    if (contSocket && authData && authData.user) {
      contSocket.emit('send-message-contact', {
        ...data,
        conversationId: conversation.id,
      });
      dispatch(
        addMsg({
          id: conversation.id,
          msg: {
            senderId: authData.user.id as number,
            content: data.content,
            createdAt: momentVi().toISOString(),
            updatedAt: momentVi().toISOString(),
            conversationId: 0,
          },
        }),
      );
    }
  };

  const callContact = () => {
    if (contSocket && conversation && conversation.owner) {
      contSocket.emit('call-contact-conversation', {
        conversationId: conversation.id,
        otherConversationId: chats.find(
          (item) =>
            item.conversation && item.conversation.id !== conversation.id,
        )
          ? chats.find(
              (item) =>
                item.conversation && item.conversation.id !== conversation.id,
            )?.conversation.id
          : undefined,
        receiverId: conversation.owner.id,
      });
    }
  };

  const handleCallTimeout = () => {
    if (contSocket) {
      contSocket.emit('call-contact-timeout', {
        conversationId: conversation?.id,
      });
    }
    dispatch(
      setIsCall({
        conversationId: conversation.id,
        call: {
          countDownTimeCall: undefined,
          isCall: false,
          isStart: false,
          isReceiver: false,
        },
      }),
    );
  };

  const handleCloseCall = () => {
    if (contSocket && conversation && conversation.owner) {
      contSocket.emit('leave-call-contact', {
        conversationId: conversation.id,
        otherUserId: conversation.owner.id,
      });
    }
  };

  const handleJoinCall = () => {
    if (contSocket && conversation && conversation.owner) {
      contSocket.emit('start-call-contact-conversation', {
        conversationId: conversation.id,
        otherUserId: conversation.owner.id,
      });
    }
  };

  const handleUploadImg = async (files: File[]) => {
    if (authData?.user && conversation && conversation?.id && contSocket) {
      const promises = files.map(
        (file) =>
          new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = function (event) {
              resolve((event.target?.result as string) || '');
            };

            reader.readAsDataURL(file);
          }),
      );
      const imgUrls = ((await Promise.all(promises)) || []) as string[];
      const images = imgUrls.map(
        (url): FileData => ({
          state: '',
          url: url || '',
          name: 'img',
          type: FileType.IMAGE,
          bucketName: `${S3.bucketNames.userConversation}`,
          uploaderId: authData.user?.id,
          id: 0,
        }),
      );

      dispatch(
        addMsg({
          id: conversation.id,
          msg: {
            content: '',
            createdAt: getDateNow().toISOString(),
            conversationId: Number(conversation.id),
            senderId: authData.user.id,
            updatedAt: getDateNow().toISOString(),
            folder: {
              files: [...images],
            },
          },
        }),
      );

      const currentDate = getDateNow();
      const nameFolder = `user-${authData.user.id}/id-conversation-${
        conversation.id
      }/folder-${currentDate.toISOString()}`;
      const filesUploadPromise = files.map(async (item, key) =>
        handleUploadFileToS3Promise({
          bucket: `${S3.bucketNames.userConversation}`,
          file: item,
          fileName: `${nameFolder}/img-${key}-${currentDate.toISOString()}.jpg`,
          typeFile: FileType.IMAGE,
          isPublic: false,
        }),
      );

      try {
        await Promise.all(filesUploadPromise);
        contSocket.emit('send-message-contact', {
          conversationId: conversation.id,
          content: '',
          bucketName: `${S3.bucketNames.userConversation}/${nameFolder}`,
          url: '',
          files: images.map((item, key) => ({
            ...item,
            url: '',
            name: `${nameFolder}/img-${key}-${currentDate.toISOString()}.jpg`,
          })),
        });
      } catch (err) {
        console.error(err);
        throw new Error('Error uploading one or more files');
      }
    }
  };

  useEffect(() => {
    if (open) {
      const element = document.getElementById(`chat-message-${itemKey}`);
      element?.scrollTo({
        behavior: 'smooth',
        top: element?.scrollHeight || 720,
      });
    }
  }, [open, conversation?.messages]);

  useEffect(() => {
    if (open) {
      dispatch(
        setIsNewMsg({
          id: conversation.id,
          isNewMsg: false,
        }),
      );
    }
  }, [open, conversation.id]);

  useEffect(() => {
    if (contSocket) {
      contSocket.on('other-user-disconnect-call', () => {
        enqueueSnackbar('Người dùng đã thoát khỏi cuộc gọi', {
          variant: 'error',
        });
        handleCloseCall();
      });
      contSocket?.on('join-room-contact-call-failed', () => {
        enqueueSnackbar('Hệ thống bị lỗi, xin vui lòng gọi lại sau', {
          variant: 'error',
        });
        dispatch(
          setIsCall({
            conversationId: conversation.id,
            call: {
              countDownTimeCall: undefined,
              isCall: false,
              isReceiver: false,
              isStart: false,
              roomUrl: '',
            },
          }),
        );
      });
    }
    return () => {
      contSocket?.off('other-user-disconnect-call');
      contSocket?.off('join-room-contact-call-failed');
    };
  }, [contSocket, open]);

  return (
    <Box key={itemKey} {...other}>
      {/* call */}
      {call.countDownTimeCall && !call.isStart && (
        <Box display={'none'}>
          <Countdown
            date={call.countDownTimeCall}
            onComplete={handleCallTimeout}
          />
        </Box>
      )}

      {call?.roomUrl && (
        <DailyProvider>
          <VoiceCall conversationId={conversation.id} roomUrl={call.roomUrl} />
        </DailyProvider>
      )}

      <ContactButton
        disable={conversation.endTime !== null}
        isNewMsg={isNewMsg}
        maxHeight={open ? '0px' : undefined}
        overflow={open ? 'hidden' : undefined}
        py={open ? '0px' : 0.5}
        user={conversation.owner}
        isCall={call.isCall && !call.isStart}
        onClick={() =>
          dispatch(
            setOpenChat({
              id: conversation.id,
              open: !open,
            }),
          )
        }
        type={conversation.type}
      />
      <ContactChatContainer
        icon={
          conversation.type === ConversationTypeType.CONSULTANT_AND_STUDENT
            ? customIcon.pen
            : customIcon.bookTeacher
        }
        title={conversation.owner?.profile.fullName}
        description={
          conversation.type === ConversationTypeType.CONSULTANT_AND_TEACHER
            ? UserType.TEACHER
            : UserType.STUDENT
        }
        handleClose={() => {
          dispatch(
            setOpenChat({
              id: conversation.id,
              open: false,
            }),
          );
          setOpenCloseConfirm(false);
        }}
        showMinus={!!conversation.owner}
        handleCloseWithMinus={() => setOpenCloseConfirm(true)}
        right={(itemKey as number) * 240}
        open={open}
        position={'relative'}
        isCall={call.isCall}
        onCall={callContact}
      >
        {authData !== null && authData.user && (
          <>
            <ContactMessages
              id={`chat-message-${itemKey}`}
              messages={conversation.messages || []}
              sender={authData.user as User}
              receiver={conversation.owner}
              isCall={call.isCall}
            />

            {conversation.owner && (
              <ConversationCall
                timeCountDown={call.countDownTimeCall}
                isCall={call.isCall}
                isReceiver={call.isReceiver}
                isStart={call.isStart}
                roomUrl={call.roomUrl}
                avatar={conversation.owner?.profile?.avatar}
                name={conversation.owner?.profile?.fullName}
                onCloseCall={handleCloseCall}
                onCall={handleJoinCall}
              />
            )}

            <ContactChatForm
              handleUploadImg={handleUploadImg}
              keyItem={itemKey}
              handleSubmit={onSubmitFormMessage}
              disabled={
                !(conversation && conversation.startTime) || openCloseConfirm
              }
            />
          </>
        )}
        {conversation.owner && conversation.rating && (
          <ContactStar
            handle={handleCloseChat}
            rating={conversation.rating}
            open
            user={conversation.owner}
          />
        )}
        {openCloseConfirm && (
          <CloseContactConfirm
            handle={handleCloseWithMinus}
            handleClose={() => setOpenCloseConfirm(false)}
          />
        )}
      </ContactChatContainer>
    </Box>
  );
}

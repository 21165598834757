import { Box, MenuItem, Stack, Typography, useTheme } from '@mui/material';
import { IconSVG } from '../IconSVG';
import React from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { grey } from '@mui/material/colors';

type Props = {
  title: string;
  icon?: string | React.ReactElement;
  path?: string;
  onClick?: VoidFunction;
};

export function getActiveItem(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: true }, pathname) : false;
}

const HeaderMenuMenuItem = ({
  title,
  icon = '',
  path = '#',
  onClick = () => {},
}: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();

  const handleClick = () => {
    onClick();
    if (path) navigate(path);
  };

  const active = getActiveItem(path, pathname);

  return (
    <MenuItem
      onClick={handleClick}
      sx={{
        ...(active && {
          backgroundColor: grey[100],
          borderLeft: `3px solid ${theme.palette.primary.main}`,
        }),
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box
          sx={{
            width: 18,
            height: 18,
          }}
        >
          {typeof icon === 'string' && <IconSVG path={icon} />}
          {React.isValidElement(icon) && icon}
        </Box>
        <Typography>{title}</Typography>
      </Stack>
    </MenuItem>
  );
};

export default HeaderMenuMenuItem;

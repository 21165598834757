import { ErrorRes } from 'src/@types/app-response';
import {
  resetVerifyBeforeEndDialog,
  setVerifyBeforeEndDialog,
} from 'src/redux/slices/header.slice';
import { useDispatch, useSelector } from 'src/redux/store';

export function useVerifyBeforeEndDialog() {
  const toggle = useSelector(
    (state) => state.header.verifyBeforeEndWork.dialog.open,
  );

  const dispatch = useDispatch();

  const open = (
    error?: ErrorRes<{ revenue?: string; expense?: string } | string>,
  ) => {
    dispatch(
      setVerifyBeforeEndDialog({
        open: true,
        ...(typeof error?.message !== 'string' && {
          error: {
            revenue: error?.message?.revenue,
            expense: error?.message?.expense,
          },
          isError: !!error,
        }),
      }),
    );
  };

  const close = () => {
    dispatch(resetVerifyBeforeEndDialog());
  };

  return { open, close, toggle };
}

export const ERROR_MESSAGE = {
  COMMON: {
    PASSWORD_NOT_CORRECT_FORMAT:
      'Yêu cầu ít nhất 8 chữ và số, có 1 chữ viết hoa',
    CONFIRM_PASS_NOT_MATCH: 'Mật khẩu không khớp',
    MALFORMED: 'Số điện thoại / Gmail không đúng định dạng',
    UPGRADE_INFO_ERROR: 'Cập nhật thông tin không thành công',
    INFO_USER_INVALID: 'Thông tin người dùng không hợp lệ',
    UPLOAD_IMAGE_SIZE: (size: string) =>
      `Vui lòng lựa chọn hình ảnh có kích thước nhỏ hơn ${size}`,
    UPLOAD_IMAGE_TYPE: (type: string) => `Chỉ chấp nhận File ${type}`,
    LOGIN: {
      ERROR_PASS: (count: number, max: number) =>
        `Mật khẩu bị sai ${count}/${max} lần`,
    },
    REGISTER: {
      PHONE_NOT_CORRECT_FORMAT: 'Số điện thoại không đúng định dạng',
      EMAIL_NOT_CORRECT_FORMAT: 'Gmail không đúng định dạng',
    },
    RESET_PASS: {
      OPT_NOT_CORRECT_FORMAT: 'Mã OTP không hợp lệ',
      OTP_NOT_SEND: 'Bạn chưa gửi mã OTP',
    },
    OTP: {
      OTP_NOT_CORRECT_FORMAT: 'Mã OTP không hợp lệ',
      OTP_NOT_SEND: 'Bạn chưa gửi mã OTP',
    },
    ACCOUNT_SETTING: {
      PHONE_NOT_CORRECT_FORMAT: 'Số điện thoại không đúng định dạng',
      EMAIL_NOT_CORRECT_FORMAT: 'Gmail không đúng định dạng',
    },
    AGE: {
      REQUIRE: 'Yêu cầu độ tuổi từ 18 đến 78 tuổi',
    },
    CCCD: {
      REQUIRE: 'Vui lòng cập nhập thông tin cccd',
      UPGRADE_ERROR: 'Cập nhật thông tin cccd không thành công',
    },
    INVALID_LEAD_REFRESH_PAGE:
      'Thông tin không còn khả dụng, vui lòng làm mới lại trang',
    INFO_NOT_VALID: 'Thông tin không hợp lệ',
    IMAGE_NOT_VALID: 'Vui lòng lựa chọn hình ảnh phù hợp',
    IN_ACTIVE_CAUSE_CHANGE_DATA:
      'Thao tác của bạn không còn khả dụng vì thông tin này đã được thay đổi trước đó.',
    TRANSACTION: {
      CANCEL_DEPOSIT: 'Hủy yêu cầu nạp tiền không thành công!',
    },
  },
  USER: {
    UPDATE_AVATAR: 'Cập nhật ảnh đại diện thất bại',
  },
};

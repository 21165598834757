import { Folder } from './folder';
import { User } from './user';
import { UserConversation } from './userConversation';

export enum ConversationTypeType {
  CONSULTANT_AND_TEACHER = 'CONSULTANT_AND_TEACHER',
  CONSULTANT_AND_STUDENT = 'CONSULTANT_AND_STUDENT',
  TEACHER_AND_STUDENTS = 'TEACHER_AND_STUDENTS',
}

export enum ConversationStatus {
  RECEIVED = 'Tiếp nhận',
  PROCESSED = 'Đã xử lý',
  NOT_PROCESSED_YET = 'Chưa xử lý',
}

interface DateResponse {
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface MessageType extends DateResponse {
  content: string;
  senderId: number;
  sender?: User;
  conversationId: number;
  conversation?: ConversationType;
  folder?: Folder;
}

export interface ConversationType extends DateResponse {
  id: number;
  ownerId: number;
  startTime?: string;
  endTime?: string;
  messages: Array<MessageType>;
  userConversations: UserConversation[];
  type: ConversationTypeType;
  owner?: User;
  rating: number;
}
export type CallConversation = {
  isCall?: boolean
  countDownTimeCall?: number
  isReceiver?: boolean
  isStart?: boolean
  roomUrl?: string
}

export type Chat = {
  conversation: ConversationType;
  isNewMsg: boolean;
  open: boolean;
  call: CallConversation
};

export interface ConversationsState {
  selectedConversationId: number | null;
  conversations: ConversationType[];
  maxChats: number;
  chats: Array<Chat>;
}

//Service
export type OverviewTaskHistoryRequest = {
  month: number;
  year: number;
};

export type OverviewTaskHistoryResponse = {
  month: number;
  year: number;
  zeroStartCount: number;
  oneStartCount: number;
  twoStartCount: number;
  threeStartCount: number;
  fourStartCount: number;
  fiveStartCount: number;
};

export type SearchTaskHistoryRequest = {
  searchCharacter?: string;
  month: number;
  year: number;
  star?: number;
};

export type SearchTaskHistoryResponse = {
  id: number;
  startTime: Date;
  rating: number;
  ownerName: string;
  KPI?: number;
};

export interface BaseDataType {
  roomId?: string;
}

export interface CreateRoomResponse extends BaseDataType {
  conversation: ConversationType;
}

export interface ExistedRoomResponse extends BaseDataType {
  conversation?: ConversationType;
}

export interface MessageResponse extends BaseDataType {
  message: MessageType;
  sender?: User;
}

import {
  Button,
  Container,
  Stack,
  Tab,
  Tabs,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React, { Suspense } from 'react';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import Loading from 'src/components/Loading';
import Page from 'src/components/Page';
import WorkCalendarDialog, {
  WorkCalendarValue,
} from 'src/components/calender/WorkCalendarDialog';
import useSettings from 'src/hooks/useSettings';
import useToggle from 'src/hooks/useToggle';
import {
  setCurrentTab,
  setMonth,
  setYear,
} from 'src/redux/slices/task-history.slice';
import { useDispatch, useSelector } from 'src/redux/store';
//For lazy

const CVRequest = React.lazy(
  () =>
    import('src/sections/consultant/@dashboard/task-history/verify-cv/List'),
);
const TuitionList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/tuition-reminder/List'
    ),
);
const FeeList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/fee-reminder/List'
    ),
);
const ClassApplicationInformation = React.lazy(
  () =>
    import('src/sections/consultant/@dashboard/task-history/verify-class/List'),
);
const Conversation = React.lazy(
  () =>
    import('src/sections/consultant/@dashboard/task-history/conversation/List'),
);
const CombineList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/class-combine/List'
    ),
);
const ClassTeachingCombineList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/class-teaching-combine/List'
    ),
);
const ReMarkingList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/re-marketing/List'
    ),
);
const ClassReminderToTeachList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/class-reminder-to-teach/List'
    ),
);
const ClassReminderToLearnList = React.lazy(
  () =>
    import(
      'src/sections/consultant/@dashboard/task-history/consultant-call-kpi/class-reminder-to-learn/List'
    ),
);

const CustomTabs = styled(Tabs)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  '::before': {
    content: "''",
    width: '100%',
    position: 'absolute',
    bottom: 0,
    borderBottom: `2px solid ${theme.palette.divider}`,
  },
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginRight: '0 !important',
  fontWeight: 700,
  textTransform: 'none',
}));

export const TASK_HISTORY_TABS_TITLE = {
  conversation: 'Tư vấn',
  classVerification: 'Xét duyệt lớp',
  marketing: 'Tiếp thị',
  learningReminder: 'Nhắc nhở học',
  cvVerification: 'Kiểm duyệt CV',
  suggestion: 'Đề xuất',
  teachingReminder: 'Nhắc nhở dạy',
  tuitionReminder: 'Nhắc học phí',
  feeReminder: 'Nhắc lệ phí',
  classCombine: 'Ghép lớp học',
  classTeachingCombine: 'Ghép lớp dạy',
};

const TASK_HISTORY_TABS = [
  {
    title: TASK_HISTORY_TABS_TITLE.conversation,
    panel: <Conversation />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.classVerification,
    panel: <ClassApplicationInformation />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.tuitionReminder,
    panel: <TuitionList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.marketing,
    panel: <ReMarkingList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.classCombine,
    panel: <CombineList/>,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.learningReminder,
    panel: <ClassReminderToLearnList/>,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.cvVerification,
    panel: <CVRequest />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.feeReminder,
    panel: <FeeList/>,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.suggestion,
    panel: '#',
  },
  {
    title: TASK_HISTORY_TABS_TITLE.classTeachingCombine,
    panel: <ClassTeachingCombineList />,
  },
  {
    title: TASK_HISTORY_TABS_TITLE.teachingReminder,
    panel: <ClassReminderToTeachList/>,
  },
];

export default function TaskHistory() {
  const month = useSelector((state) => state.taskHistory.consultant.month);
  const year = useSelector((state) => state.taskHistory.consultant.year);
  const currentTab = useSelector(
    (state) => state.taskHistory.consultant.currentTab,
  );

  const {
    onClose: onCloseCalendar,
    onOpen: onOpenCalendar,
    toggle: calendarToggle,
  } = useToggle();
  const theme = useTheme();
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  const handleCalendarOnClick = (data: WorkCalendarValue) => {
    dispatch(setMonth({ month: data.month, userType: 'consultant' }));
    dispatch(setYear({ year: data.year, userType: 'consultant' }));
  };

  const handleOnchangeTab = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string,
  ) => {
    dispatch(setCurrentTab({ currentTab: newValue, userType: 'consultant' }));
  };

  const actions = (
    <Stack
      direction="row"
      alignItems={'center'}
      spacing={2}
      justifyContent={'flex-end'}
    >
      <Typography variant="h5" color={theme.palette.primary.main}>
        Tháng {month} Năm {year}
      </Typography>
      <Button variant="reserveContained" onClick={onOpenCalendar}>
        Chọn lịch khác
      </Button>
    </Stack>
  );

  const currentTabData = TASK_HISTORY_TABS.find(
    (tab) => tab.title === currentTab,
  );

  return (
    <Page title="Lịch sử công việc của bạn">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Lịch sử công việc của bạn"
          links={[]}
          actions={actions}
          actionsDisplay="inline"
        />

        <Stack spacing={4} mt={4}>
          <Stack direction={'row'}>
            <CustomTabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={handleOnchangeTab}
            >
              {TASK_HISTORY_TABS.map((tab, index) => (
                <CustomTab key={index} label={tab.title} value={tab.title} />
              ))}
            </CustomTabs>
          </Stack>

          <Suspense fallback={<Loading />}>{currentTabData?.panel}</Suspense>
        </Stack>

        {/* other */}
        <WorkCalendarDialog
          open={calendarToggle}
          onClose={onCloseCalendar}
          onClick={handleCalendarOnClick}
          value={{
            month,
            year,
          }}
        />
      </Container>
    </Page>
  );
}

import {
  Box,
  ListItemButton,
  ListItemButtonProps,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React, { ReactElement, useRef } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { AccountantRoleType } from 'src/@types/user';
import {
  WorkAttendance,
  WorkAttendanceStatusType,
} from 'src/@types/work-attendance';
import { QUERY_KEYS } from 'src/constants/queryKey';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';
import HeaderMenuMenu, { HeaderMenuMenuHandle } from './HeaderMenuMenu';
import useAuth from 'src/hooks/useAuth';

//----------------------------------------------------------------
type CustomListItemButtonProps = {
  active?: 'true' | 'false';
};

const CustomListItemButton = styled(ListItemButton)<CustomListItemButtonProps>(
  ({ theme, active = 'false' }) => ({
    height: '100%',
    padding: theme.spacing(0, 4),
    position: 'relative',
    ...(active === 'true' && {
      '::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        width: '80%',
        height: 4,
        backgroundColor: 'white',
        left: '50%',
        transform: 'translateX(-50%)',
        borderRadius: '2px 2px',
      },
    }),
  }),
);

//----------------------------------------------------------------
export type HeaderMenuItemProps = {
  title: string;
  path?: string;
  icon?: ReactElement;
  roles?: string[];
  items?: HeaderMenuItemProps[];
  isOutWorkTime?: boolean;
  onClick?: VoidFunction;
};

type Props = HeaderMenuItemProps & ListItemButtonProps;

export function isExternalLink(path: string) {
  return path.includes('http');
}

export function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false;
}

export default function HeaderMenuItem({
  path = '#',
  title,
  icon,
  roles,
  items,
  isOutWorkTime,
  onClick,
  ...other
}: Props) {
  const { isSignInByManager } = useAuth();
  const navigate = useNavigate();

  const menuRef = useRef<HeaderMenuMenuHandle | null>(null);

  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const workAttendance = queryClient.getQueryData<WorkAttendance>([
    QUERY_KEYS.WORK_ATTENDANCE,
    QUERY_KEYS.WORKING_NOW,
  ]);

  const active = getActive(path, pathname);

  const handleClickItem = (event: any) => {
    event.preventDefault();

    if (onClick) {
      return onClick();
    }

    if (isSignInByManager) return navigate(path);
    if (isOutWorkTime) return navigate(path);
    if (roles?.includes(AccountantRoleType.MANAGER)) return navigate(path);

    if (
      !workAttendance ||
      workAttendance.status !== WorkAttendanceStatusType.WORKING
    ) {
      return enqueueSnackbar(
        'Bạn đang ngoài giờ làm việc hoặc chưa bấm nút bắt đầu làm!',
        {
          variant: 'warning',
        },
      );
    }

    return navigate(path);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (menuRef.current) {
      menuRef.current.openMenu(event);
    }
  };

  if (items && items.length) {
    return (
      <RoleBasedGuard roles={roles}>
        <CustomListItemButton
          active={active ? 'true' : 'false'}
          onClick={handleOpenMenu}
          {...other}
        >
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            <Box
              sx={{
                width: 18,
                height: 18,
              }}
            >
              {icon}
            </Box>
            <Typography fontWeight={600}>{title}</Typography>
          </Stack>
        </CustomListItemButton>
        <HeaderMenuMenu ref={menuRef} items={items} />
      </RoleBasedGuard>
    );
  }

  return (
    <RoleBasedGuard roles={roles}>
      <CustomListItemButton
        active={active ? 'true' : 'false'}
        onClick={handleClickItem}
        {...other}
      >
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <Box
            sx={{
              width: 18,
              height: 18,
            }}
          >
            {icon}
          </Box>
          <Typography fontWeight={600} noWrap>
            {title}
          </Typography>
        </Stack>
      </CustomListItemButton>
    </RoleBasedGuard>
  );
}

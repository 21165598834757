import * as Yup from 'yup';

//----------------------------------------------------------------
export interface IFileState {
  bucket: string;
  nameFolder: string;
  fileToUpload: File | null;
  form: {
    file: FileData | null;
    isError: boolean;
    error: any;
  };
  isLoading: boolean;
  isUpload: boolean;
}

//----------------------------------------------------------------

export enum FileType {
  IMAGE = 'images',
  PDF = 'pdf',
  AUDIO = 'audio',
  EXCEL = 'excel',
  CSV = 'csv',
  VIDEO = 'video',
  OTHER = 'other',
}

export enum SupportFileType {
  png = 'png',
  jpg = 'jpg',
  jpeg = 'jpeg',
  gif = 'gif',
  svg = 'svg',
  pdf = 'pdf',
  doc = 'doc',
  docx = 'docx',
  ppt = 'ppt',
  pptx = 'pptx',
  mp3 = 'mp3',
  mp4 = 'mp4',
  wav = 'wav',
  xlsx = 'xlsx',
  xls = 'xls',
  csv = 'csv',
}

export type FileData = {
  id?: number;
  url?: string;
  name?: string;
  type?: FileType;
  state?: any;
  size?: number;
  uploaderId?: number;
  bucketName?: string;
  folderId?: number;
};

export function isFileData(obj: any): obj is FileData {
  return typeof obj === 'object' && obj !== null && typeof obj.url === 'string';
}

export const fileDataSchema = Yup.object({
  id: Yup.number().typeError('').optional(),
  url: Yup.string().optional(),
  name: Yup.string().optional(),
  size: Yup.number().typeError('').optional(),
  type: Yup.mixed<FileType>().optional(),
  lastModified: Yup.date().optional(),
  folderId: Yup.number().required(),
  state: Yup.string().optional(),
  uploaderId: Yup.number().optional(),
  bucketName: Yup.string().optional(),
});

export const fileSchema = Yup.mixed().test(
  'is-file',
  '',
  (value) => value instanceof File,
);

export const requiredFileSchema = Yup.lazy((value) => {
  if (value instanceof File) {
    return fileSchema.required('');
  } else if (typeof value === 'object' && value !== null && 'id' in value) {
    return fileDataSchema.required('');
  }
  return Yup.mixed().required() as any;
});

import { DashboardSideType } from 'src/@types/dashboard';
import {
  setManagerVerifyDialog,
  setManagerVerifySelectedUserId,
} from 'src/redux/slices/company-money-holder-account-verification.slice';
import { useDispatch, useSelector } from 'src/redux/store';

type Props = {
  dashboardSide: DashboardSideType;
};

export function useVerifyCompanyMoneyHolderAccountVerificationDialog({
  dashboardSide,
}: Props) {
  //state
  const toggle = useSelector(
    (state) =>
      state.companyMoneyHolderAccountVerification.manager[dashboardSide].verify
        .dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  //open
  const openDialogByUserId = (
    userId: number,
    groupId?: number,
    date?: string,
  ) => {
    dispatch(
      setManagerVerifyDialog({
        dashboardSide,
        dialog: {
          toggle: true,
          groupId,
          date,
        },
      }),
    );
    dispatch(
      setManagerVerifySelectedUserId({
        dashboardSide,
        selectedUserId: userId,
      }),
    );
  };

  //close
  const closeDialog = () => {
    dispatch(
      setManagerVerifyDialog({
        dashboardSide,
        dialog: {
          toggle: false,
        },
      }),
    );
    dispatch(
      setManagerVerifySelectedUserId({
        dashboardSide,
        selectedUserId: undefined,
      }),
    );
  };

  return { toggle, openDialogByUserId, closeDialog };
}

import { Box, Stack } from '@mui/material';
import { grey } from '@mui/material/colors';
import WorkMonthCalendar from './WorkMonthCalendar';
import WorkYearCalendar from './WorkYearCalendar';
import { WorkCalendarValue } from './WorkCalendarDialog';

type Props = {
  value?: WorkCalendarValue;
  onYearClick?: (value: number) => void;
  onMonthClick?: (value: number) => void;
  beforeYearCount?: number;
};

export default function WorkCalendar({
  value: data,
  onYearClick,
  onMonthClick,
  beforeYearCount = 1,
}: Props) {
  return (
    <Stack direction={'row'} height={370}>
      <WorkYearCalendar
        flex={1}
        onYearClick={onYearClick}
        value={data?.year}
        beforeYearCount={beforeYearCount}
      />
      <Box sx={{ width: 10, backgroundColor: grey[100] }} />
      <WorkMonthCalendar
        flex={2.5}
        onMonthClick={onMonthClick}
        value={data?.month}
      />
    </Stack>
  );
}

import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import { lazy, Suspense, useMemo } from 'react';
import { WorkAttendanceStatusType } from 'src/@types/work-attendance';
import Iconify from 'src/components/Iconify';
import { useWorkingNow } from 'src/hooks/common/work-attendance/useWorkingNow';
import { useCheckInTimeWork } from 'src/hooks/common/work-shift/useCheckInTimeWork';
import useAuth from 'src/hooks/useAuth';
import useToggle from 'src/hooks/useToggle';
import { getPrefixByUserType } from 'src/utils/getAuth';
const StartDialog = lazy(() => import('./StartDialog'));
const EndDialog = lazy(() => import('./EndDialog'));
const VerifyBeforeEndDialog = lazy(
  () =>
    import(
      '../../../sections/accountant/@dashboard/verify-before-end/dialog/VerifyBeforeEndDialog'
    ),
);

const StartLoadingButtonCustom = styled(LoadingButton)(() => ({
  borderRadius: 50,
  background: `linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(45,82,253,1) 100%)`,
  width: 200,
}));

const EndLoadingButtonCustom = styled(LoadingButton)(() => ({
  borderRadius: 50,
  background: `linear-gradient(0deg, rgba(187,176,25,1) 12%, rgba(238,157,0,1) 81%)`,
  width: 200,
}));

export default function AttendanceTracking() {
  const { userType } = useAuth();
  const prefix = useMemo(() => getPrefixByUserType(userType), [userType]);

  //hooks
  const {
    onClose: onStartDialogClose,
    onOpen: onStartDialogOpen,
    toggle: startDialogToggle,
  } = useToggle();
  const {
    onClose: onEndDialogClose,
    onOpen: onEndDialogOpen,
    toggle: endDialogToggle,
  } = useToggle();
  const { enqueueSnackbar } = useSnackbar();

  //Call Api
  const { data, error } = useCheckInTimeWork(prefix);

  const { data: workAttendance } = useWorkingNow(
    {
      fopId: data?.id || 0,
    },
    prefix,
  );

  const handleWorkBtnClick = () => {
    if (error) {
      if (error.statusCode >= 500)
        return enqueueSnackbar(
          'Hiện tại bạn chưa tới giờ làm hoặc chưa đăng ký lịch',
          {
            variant: 'warning',
          },
        );

      return enqueueSnackbar(error.message, { variant: 'error' });
    }

    onStartDialogOpen();
  };

  const handleDoneBtnClick = () => {
    onEndDialogOpen();
  };

  return (
    <>
      {workAttendance &&
      workAttendance.status === WorkAttendanceStatusType.WORKING ? (
        <EndLoadingButtonCustom
          variant="contained"
          color="warning"
          endIcon={<Iconify icon="material-symbols:logout" />}
          onClick={handleDoneBtnClick}
        >
          Kết thúc làm
        </EndLoadingButtonCustom>
      ) : (
        <StartLoadingButtonCustom
          variant="contained"
          color="info"
          endIcon={<Iconify icon="material-symbols:login" />}
          onClick={handleWorkBtnClick}
        >
          Bắt đầu làm
        </StartLoadingButtonCustom>
      )}
      <Suspense>
        {startDialogToggle && (
          <StartDialog open={startDialogToggle} onClose={onStartDialogClose} />
        )}
        {endDialogToggle && (
          <EndDialog open={endDialogToggle} onClose={onEndDialogClose} />
        )}
        <VerifyBeforeEndDialog open={false} />
      </Suspense>
    </>
  );
}

// routes
import { PATH_ACCOUNTANT } from '../../../routes/paths';
// components
import { AccountantRoleType } from 'src/@types/user';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  termPolicy: getIcon('term-policy-icon'),
  customGraduationHat: getIcon('custom-graduation-hat'),
  customFileTick: getIcon('custom-file-tick'),
  checkClass: getIcon('check_class_icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
  depositRequest: getIcon('deposit-request-icon'),
  depositSupport: getIcon('deposit-support-icon'),
  expenseOverview: getIcon('expense-overview-icon'),
  expenseWallet: getIcon('expense-wallet-icon'),
  incomeOverview: getIcon('income-overview-icon'),
  incomeWallet: getIcon('income-wallet-icon'),
  salaryPayment: getIcon('salary-payment-icon'),
  undefinedDeposit: getIcon('undefined-deposit-icon'),
  undefinedRefund: getIcon('undefined-refund-icon'),
  withdrawRequest: getIcon('withdraw-request-icon'),
  withdrawSupport: getIcon('withdraw-support-icon'),
  checkmarkMaleUserIcon: getIcon('checkmark-male-user-icon'),
  chatDeleteIcon: getIcon('chat-delete-icon'),
  authenticationIcon: getIcon('authentication-icon'),
  removeNoteIcon: getIcon('remove-note-icon'),
  depositReportIcon: getIcon('deposit-report-icon'),
  withdrawReportIcon: getIcon('withdraw-report-icon'),
  verifyWithdrawMoney: getIcon('verify-withdraw-money'),
  verifyWithdrawMoney2: getIcon('verify-withdraw-money-2'),
  verifyDepositMoney: getIcon('verify-deposit-money'),
  endOfShiftVerificationExpense: getIcon('end-of-shift-verification-expense'),
  endOfShiftVerificationRevenue: getIcon('end-of-shift-verification-revenue'),
  tablerContract: getIcon('tabler-contract'),
};

// STAFF
// ----------------------------------------------------------------------
const navConfig = [
  // ----------------------------------------------------------------------
  // Income
  {
    subheader: 'Thu tiền',
    icon: ICONS.incomeWallet,
    items: [
      // Deposit
      {
        title: 'Yêu cầu nạp tiền',
        path: PATH_ACCOUNTANT.depositRequest,
        icon: ICONS.depositRequest,
        roles: [AccountantRoleType.STAFF],
      },
      // Deposit support
      {
        title: 'Hỗ trợ nạp tiền',
        path: PATH_ACCOUNTANT.depositSupport,
        icon: ICONS.depositSupport,
        roles: [AccountantRoleType.STAFF],
      },
      // Undefined Deposit
      {
        title: 'Nhận chưa xác định',
        path: PATH_ACCOUNTANT.undefinedReceipt,
        icon: ICONS.undefinedDeposit,
        roles: [AccountantRoleType.STAFF],
      },
      // Income overview
      {
        title: 'Thống kê tổng thu',
        path: PATH_ACCOUNTANT.incomeOverview,
        icon: ICONS.incomeOverview,
        roles: [AccountantRoleType.STAFF],
      },
      //Verify Undefined Deposit
      {
        title: 'Báo cáo nạp tiền',
        path: PATH_ACCOUNTANT.reportDepositTransaction,
        icon: ICONS.depositReportIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Xác minh NCXD',
        path: PATH_ACCOUNTANT.undefinedReceiptVerification,
        icon: ICONS.checkmarkMaleUserIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      //Delete Undefined Deposit
      {
        title: 'Duyệt xóa NCXD',
        path: PATH_ACCOUNTANT.undefinedReceiptDeletion,
        icon: ICONS.chatDeleteIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      //Income Company withdraw
      {
        title: 'Duyệt tiền lấy về',
        path: PATH_ACCOUNTANT.verifyIncomeCompanyWithdrawTransaction,
        icon: ICONS.verifyWithdrawMoney,
        roles: [AccountantRoleType.MANAGER],
      },
      //Revenue Company withdraw
      {
        title: 'Duyệt thu cuối ca',
        path: PATH_ACCOUNTANT.verifyRevenueMoneyHolderAccountVerification,
        icon: ICONS.endOfShiftVerificationRevenue,
        roles: [AccountantRoleType.MANAGER],
      },
      //Revenue compensation log
      {
        title: 'Duyệt xử lý bồi thường',
        path: PATH_ACCOUNTANT.verifyRevenueCompensation,
        icon: ICONS.tablerContract,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Chi tiền',
    icon: ICONS.expenseWallet,
    items: [
      //Staff
      //--Withdraw
      {
        title: 'Yêu cầu rút tiền',
        path: PATH_ACCOUNTANT.withdrawRequest,
        icon: ICONS.withdrawRequest,
        roles: [AccountantRoleType.STAFF],
      },
      //--Withdraw support
      {
        title: 'Hỗ trợ rút tiền',
        path: PATH_ACCOUNTANT.withdrawSupport,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.withdrawSupport,
      },
      //--Undefined Withdraw
      {
        title: 'Hoàn chưa xác định',
        path: PATH_ACCOUNTANT.undefinedRefund,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.undefinedRefund,
      },

      //--Income overview
      {
        title: 'Thống kê tổng chi',
        path: PATH_ACCOUNTANT.expenseOverview,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.expenseOverview,
      },
      //For manager
      //--Withdraw Report
      {
        title: 'Báo cáo rút tiền',
        path: PATH_ACCOUNTANT.reportWithdrawTransaction,
        icon: ICONS.withdrawReportIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      //--Verify Undefined Withdraw
      {
        title: 'Xác minh HCXD',
        path: PATH_ACCOUNTANT.undefinedRefundVerification,
        icon: ICONS.authenticationIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      //--Delete Undefined Withdraw
      {
        title: 'Duyệt xóa HCXD',
        path: PATH_ACCOUNTANT.undefinedRefundDeletion,
        icon: ICONS.removeNoteIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      //Company withdraw
      {
        title: 'Duyệt tiền lấy về',
        path: PATH_ACCOUNTANT.verifyExpenseCompanyWithdrawTransaction,
        icon: ICONS.verifyWithdrawMoney2,
        roles: [AccountantRoleType.MANAGER],
      },
      //Company withdraw
      {
        title: 'Duyệt tiền thêm vào',
        path: PATH_ACCOUNTANT.verifyExpenseCompanyDepositTransaction,
        icon: ICONS.verifyDepositMoney,
        roles: [AccountantRoleType.MANAGER],
      },
      //Expense Company withdraw
      {
        title: 'Duyệt chi cuối ca',
        path: PATH_ACCOUNTANT.verifyExpenseMoneyHolderAccountVerification,
        icon: ICONS.endOfShiftVerificationExpense,
        roles: [AccountantRoleType.MANAGER],
      },
      //Expense compensation log
      {
        title: 'Duyệt xử lý bồi thường',
        path: PATH_ACCOUNTANT.verifyExpenseCompensation,
        icon: ICONS.tablerContract,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
  // ----------------------------------------------------------------------
  {
    subheader: 'Nội bộ',
    icon: ICONS.expenseWallet,
    items: [
      //Staff
      //--Request Salary
      {
        title: 'Thanh toán lương',
        path: PATH_ACCOUNTANT.salaryPayment,
        roles: [AccountantRoleType.STAFF],
        icon: ICONS.salaryPayment,
      },
    ],
  },
];

export default navConfig;

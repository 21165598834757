import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionCompensationState } from 'src/@types/transaction-compensation';

const initialState: TransactionCompensationState = {
  default: {
    compensationPayment: {
      dialog: {
        toggle: false,
      },
    },
  },
};

export const transactionCompensationSlice = createSlice({
  name: 'transactionCompensation',
  initialState,
  reducers: {
    setCompensationPaymentDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationState['default']['compensationPayment']['dialog']
      >,
    ) => {
      state.default.compensationPayment.dialog = {
        ...state.default.compensationPayment.dialog,
        ...action.payload,
      };
    },
    resetCompensationPaymentDialog: (state) => {
      state.default.compensationPayment.dialog =
        initialState.default.compensationPayment.dialog;
    },
    setSelectedCompensationTransactionId: (
      state,
      action: PayloadAction<number>,
    ) => {
      state.selectedTransactionCompensationId = action.payload;
    },
  },
});

export default transactionCompensationSlice.reducer;

export const {
  setCompensationPaymentDialog,
  resetCompensationPaymentDialog,
  setSelectedCompensationTransactionId,
} = transactionCompensationSlice.actions;

import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
    black: true;
  }

  interface ButtonPropsVariantOverrides {
    reserveContained: true;
    reserveContainedNotBg: true;
  }
}

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0.5, 2),
          '&:hover': {
            boxShadow: 'none',
          },
          textTransform: 'none',
        },
        sizeLarge: {
          height: 48,
        },
        // reserve contained
        reserveContained: {
          '&.Mui-disabled': {
            backgroundColor: '#f8f8f8',
          },
        },
        reserveContainedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        reserveContainedPrimary: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: theme.palette.primary.lighter,
          color: theme.palette.primary.main,
          '&:hover': {
            color: theme.palette.primary.dark,
            backgroundColor: theme.palette.primary.light,
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        reserveContainedInfo: {
          // boxShadow: theme.customShadows.info,
          backgroundColor: theme.palette.info.main + '20',
          color: theme.palette.info.main,
          '&:hover': {
            color: theme.palette.info.dark,
            backgroundColor: theme.palette.info.main + '40',
          },
        },
        reserveContainedSuccess: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: theme.palette.success.lighter,
          color: theme.palette.success.main,
          '&:hover': {
            backgroundColor: theme.palette.success.light,
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedWarning: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: theme.palette.warning.lighter,
          color: theme.palette.warning.main,
          '&:hover': {
            backgroundColor: theme.palette.warning.light,
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedBlack: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: '#21212120',
          color: theme.palette.black.main,
          '&:hover': {
            backgroundColor: '#21212130',
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedError: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: theme.palette.error.lighter,
          color: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.light,
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedNotBgError: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: 'white',
          color: theme.palette.error.main,
          '&:hover': {
            backgroundColor: theme.palette.error.main,
            color: 'white',
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        reserveContainedNotBgPrimary: {
          boxShadow: 'box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;',
          backgroundColor: 'white',
          color: theme.palette.primary.main,
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            color: 'white',
          },
          '&.MuiButton-reserveContainedSizeSmall': {
            padding: theme.spacing(0.5, 3),
          },
        },
        // contained
        contained: {
          color: '#fff',
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: theme.customShadows.z8,
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        containedPrimary: {
          boxShadow: theme.customShadows.primary,
        },
        containedSecondary: {
          boxShadow: theme.customShadows.secondary,
        },
        containedInfo: {
          boxShadow: theme.customShadows.info,
        },
        containedSuccess: {
          boxShadow: theme.customShadows.success,
        },
        containedWarning: {
          boxShadow: theme.customShadows.warning,
        },
        containedError: {
          boxShadow: theme.customShadows.error,
        },
        containedBlack: {
          ':hover': {
            backgroundColor: theme.palette.black.light,
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}

import { useDispatch } from 'react-redux';
import {
  resetManagerVerifyDialog,
  setManagerVerifyDialog,
} from 'src/redux/slices/transaction-compensation-log.slice';
import { useSelector } from 'src/redux/store';

export default function useVerifyDialog() {
  const toggle = useSelector(
    (state) => state.transactionCompensationLog.manager.verify.dialog.toggle,
  );

  //hook
  const dispatch = useDispatch();

  const open = () => {
    dispatch(setManagerVerifyDialog({ toggle: true }));
  };

  const close = () => {
    dispatch(resetManagerVerifyDialog());
  };

  return { open, close, toggle };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHeaderState } from 'src/@types/header';

const initialState: IHeaderState = {
  verifyBeforeEndWork: {
    dialog: {
      open: false,
      error: {
        revenue: '',
        expense: '',
      },
      isError: false,
    },
  },
};

//Create slice
const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setVerifyBeforeEndDialog: (
      state,
      action: PayloadAction<
        Partial<IHeaderState['verifyBeforeEndWork']['dialog']>
      >,
    ) => {
      state.verifyBeforeEndWork.dialog = {
        ...state.verifyBeforeEndWork.dialog,
        ...action.payload,
      };
    },
    resetVerifyBeforeEndDialog: (state) => {
      state.verifyBeforeEndWork.dialog =
        initialState.verifyBeforeEndWork.dialog;
    },
  },
});

export default slice.reducer;

//Export actions
export const { setVerifyBeforeEndDialog, resetVerifyBeforeEndDialog } =
  slice.actions;

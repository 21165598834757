export { default as PlanFreeIcon } from './icon_plan_free';
export { default as PlanPremiumIcon } from './icon_plan_premium';
export { default as PlanStarterIcon } from './icon_plan_starter';
export { default as SentIcon } from './icon_sent';
export { default as ShieldActiveIcon } from './icon_shield_active';
export { default as ForbiddenIllustration } from './illustration_403';
export { default as PageNotFoundIllustration } from './illustration_404';
export { default as SeverErrorIllustration } from './illustration_500';
export { default as BookingIllustration } from './illustration_booking';
export { default as CheckInIllustration } from './illustration_checkin';
export { default as CheckOutIllustration } from './illustration_checkout';
export { default as ComingSoonIllustration } from './illustration_coming_soon';
export { default as DocIllustration } from './illustration_doc';
export { default as MaintenanceIllustration } from './illustration_maintenance';
export { default as MotivationIllustration } from './illustration_motivation';
export { default as OrderCompleteIllustration } from './illustration_order_complete';
export { default as SeoIllustration } from './illustration_seo';
export { default as UploadIllustration } from './illustration_upload';

const root = '/assets/icons/common';

const path = (root: string, svgName: string) => `${root}/${svgName}.svg`;

export const customIcon = {
  squareChat: path(root, 'squareChat'),
  bookAndMark: path(root, 'ic_book_n_mark'),
  puzzle: path(root, 'ic_puzzle'),
  school: path(root, 'ic_school'),
  gender: path(root, 'ic_gender'),
  marker: path(root, 'ic_marker'),
  markerBg: path(root, 'ic_maker_bg'),
  experience: path(root, 'ic_experience'),
  workBag: path(root, 'ic_work_bag'),
  arrowRight: path(root, 'ic_arrow_right'),
  arrowRightCV: path(root, 'ic_arrow_right_cv'),
  arrowTop: path(root, 'ic_arrow_top_cv'),
  offlineClass: path(root, 'ic_offline_class'),
  onlineClass: path(root, 'ic_online_class'),
  addUser: path(root, 'ic_add_user'),
  arrowLeft1: path(root, 'arrowLeft'),
  arrowLeft2: path(root, 'arrowLeft2'),
  arrowRight1: path(root, 'arrowRight'),
  arrowRight2: path(root, 'arrowRight2'),
  screenInfo: path(root, 'screenInfo'),
  screenStudent: path(root, 'screenStudent'),
  oClockCircle: path(root, 'oClockCircle'),
  personPlush: path(root, 'personPlush'),
  location: path(root, 'location'),
  speaker: path(root, 'ic_speaker'),
  notification: path(root, 'notification'),
  chat: path(root, 'chat'),
  close: path(root, 'close'),
  contact: path(root, 'contact'),
  send: path(root, 'send'),
  newMsg: path(root, 'newMsg'),
  bookTeacher: path(root, 'book-teacher-icon'),
  pen: path(root, 'pen-icon'),
  iconPen: path(root, 'icon_pen'),
  chart: path(root, 'chart-icon'),
  image: path(root, 'image'),
  imageOutline: path(root, 'imageOutline'),
  uploadFile: path(root, 'uploadFile'),
  uploadFileDisable: path(root, 'uploadFileDisable'),
  uploadFileOutline: path(root, 'uploadFileOutline'),
  lock: path(root, 'lock'),
  zoomImage: path(root, 'zoomImage'),
  undefinedTransaction: path(root, 'undefinedTransaction'),
  historyVerification: path(root, 'historyVerification'),
  checkmarkMaleUserIcon: path(root, 'checkmark-male-user-icon'),
  avatar: path(root, 'avatar'),
  learnAtHome: path(root, 'learnAtHome'),
  logo: path(root, 'logo'),
  pin: path(root, 'pin'),
  closeWithBorder: path(root, 'closeWithBorder'),
  volumeUp: path(root, 'volumeUp'),
  volumeOff: path(root, 'volumeOff'),
  calculate: path(root, 'calculate'),
  plush: path(root, 'icon_plush'),
  iconGender: path(root, 'icon_gender'),
  iconDob: path(root, 'icon_dob'),
  iconDob2: path(root, 'icon_dob_2'),
  iconList: path(root, 'icon_list'),
  iconWorkPosition: path(root, 'icon_work_position'),
  iconTimeOldExperience: path(root, 'icon_time_old_experience'),
  iconCompany: path(root, 'icon_company'),
  iconLevel: path(root, 'icon_level'),
  iconSchool: path(root, 'icon_school'),
  incomeWalletIcon: path(root, 'income-wallet-icon'),
  expenseWalletIcon: path(root, 'expense-wallet-icon'),
  iconContractHandover: path(root, 'icon_contract_handover'),
  iconOClock: path(root, 'icon_o_clock'),
  iconFolder: path(root, 'icon_folder'),
  iconSetting: path(root, 'icon_setting'),
  iconPhone: path(root, 'icon_phone'),
  iconEmail: path(root, 'icon_email'),
  iconValid: path(root, 'icon_valid'),
  iconUnValid: path(root, 'icon_un_valid'),
  iconBlockLoader: path(root, 'icon_block_loader'),
  iconQuote: path(root, 'icon_quote'),
  iconUploadFileOutline: path(root, 'icon_upload_file_outline'),
  iconSlickLeft: path(root, 'icon_slick_left'),
  iconSlickRight: path(root, 'icon_slick_right'),
  iconTrash: path(root, 'icon_trash'),
  iconCopy: path(root, 'icon_copy'),
  iconSalaryRequest: path(root, 'icon_salary_request'),
  iconInfoPopover: path(root, 'icon_info_popover'),
  iconAtm: path(root, 'icon_atm'),
  iconMethodTransactionChecked: path(root, 'icon_method_transaction_checked'),
  icon_eye: path(root, 'icon_eye'),
  icon_eye_thick: path(root, 'icon_eye_thick'),
  iconStaticSalary: path(root, 'icon_static_salary'),
  iconTraining: path(root, 'icon_training')
};

export const audioPath = {
  ring: `/assets/audios/ring.mp3`,
  ringtone: `/assets/audios/ringtone.mp3`,
  facebook: '/assets/audios/facebook.mp3',
  threads: '/assets/audios/threads.mp3',
  ringtone2: `/assets/audios/ringtone2.mp3`,
};

const imageRoot = '/assets/images/';

export const imagePath = {
  deleteUndefinedTransaction: imageRoot + 'delete_undefined_transaction.png',
  deleteUndefinedTransaction1: imageRoot + 'delete_undefined_transaction_1.png',
  rejectedVerificationUndefinedTransaction:
    imageRoot + 'rejected_verification_undefined_transaction.png',
  rejectComTran: imageRoot + 'reject_com_tran.png',
  cheating: imageRoot + 'cheating.png',
  transactionReportRejected: imageRoot + 'transaction_report_rejected.png',
  off: imageRoot + 'off.png',
  workShiftRegister: imageRoot + 'work_shift_register.png',
  workShiftRegister1: imageRoot + 'work_shift_register_1.png',
  workStart: imageRoot + 'work_start.svg',
  workEnd: imageRoot + 'work_end.svg',
  forbidden: imageRoot + 'forbidden.svg',
  confirmDeleteRecruitment: imageRoot + 'confirm_delete_recruitment.png',
  confirmEndRecruitment: imageRoot + 'confirm_end_recruitment.png',
  bankAccountSufficientBalance:
    imageRoot + 'bank_account_sufficient_balance.png',
  confirmDeleteContract: imageRoot + 'confirm_delete_contract.png',
  confirmCreateContractHandover:
    imageRoot + 'confirm_create_contract_handover.png',
  verify: imageRoot + 'verify.png',
  fileTypeError: imageRoot + 'fileTypeError.png',
  warningLayOff: imageRoot + 'warning_lay_off.png',
  verifyUserTrainingSection: imageRoot + 'verify_user_training_section.png',
  staticUp: imageRoot + 'static_up.png',
  staticDown: imageRoot + 'static_down.png',
  user: imageRoot + 'user.png',
};

export const notFoundImagePath = {
  notFound1: '/assets/not-found/not-found-1.png',
  notFound2: '/assets/not-found/not-found-2.png',
  notFound3: '/assets/not-found/not-found-3.png',
  notFound4: '/assets/not-found/not-found-4.png',
  notFound5: '/assets/not-found/not-found-5.png',
  notFound6: '/assets/not-found/not-found-6.png',
  notFoundRecruitmentApplication:
    '/assets/not-found/not-found-recruitment-application.png',
  notFoundVideo: '/assets/not-found/not-found-video.png',
  notFoundImage: '/assets/not-found/not-found-image.png',
};

import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
// routes
import { PATH_ACCOUNTANT } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { MenuOption } from 'src/@types/common';
import { AccountantRoleType } from 'src/@types/user';
import { customIcon } from 'src/assets';
import Iconify from 'src/components/Iconify';
import { HEADER } from 'src/config';
import MenuPopover from '../../../components/MenuPopover';
import MyAvatar from '../../../components/MyAvatar';
import { IconButtonAnimate } from '../../../components/animate';
import { IconSVG } from 'src/components/IconSVG';

// ----------------------------------------------------------------------
const MENU_OPTIONS_STAFF: MenuOption[] = [
  {
    label: 'Lịch sử nhận lương',
    linkTo: '/ke-toan/lich-su-nhan-luong',
    icon: customIcon.oClockCircle,
  },
  {
    label: 'Hồ sơ nhận việc',
    linkTo: '/ke-toan/ho-so-nhan-viec',
    icon: customIcon.iconFolder,
  },
  {
    label: 'Quản lý tài khoản',
    linkTo: '/ke-toan/quan-ly-tai-khoan',
    icon: customIcon.iconSetting,
  },
];

// ----------------------------------------------------------------------
export default function AccountPopover() {
  const navigate = useNavigate();
  const theme = useTheme();

  const { user, logout, userRole } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_ACCOUNTANT.auth.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const menuOptions =
    userRole === AccountantRoleType.STAFF ? MENU_OPTIONS_STAFF : [];

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar
          sx={{
            height: HEADER.AVATAR_SIZE,
            width: HEADER.AVATAR_SIZE,
          }}
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Stack alignItems={'center'} direction="row" spacing={1}>
            <MyAvatar />
            <Stack>
              <Typography variant="subtitle2" noWrap>
                Tài khoản kế toán
              </Typography>
              <Typography variant="subtitle2" noWrap>
                {user?.profile?.fullName}
              </Typography>
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.lighter,
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  px: 1,
                }}
                mt={1}
              >
                <Typography
                  variant="caption"
                  sx={{ color: 'primary.main' }}
                  noWrap
                >
                  {user?.accountant?.code || '---'}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }} spacing={2}>
          {menuOptions.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              <Stack direction={'row'} alignItems={'center'}>
                <Box width={'30px'}>
                  <IconSVG
                    path={option.icon}
                    css={{
                      width: '15px',
                      height: '15px',
                      lineHeight: '0px',
                    }}
                  />
                </Box>
                {option.label}
              </Stack>
            </MenuItem>
          ))}
        </Stack>

        <Stack p={2} />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Iconify
              icon="solar:logout-2-outline"
              sx={{ width: 20, height: 20 }}
            />
            <Typography variant="body2">Đăng xuất</Typography>
          </Stack>
        </MenuItem>
      </MenuPopover>
    </>
  );
}

import { ReactNode } from 'react';
// @mui
import { Box, Link, Stack, StackProps, Typography } from '@mui/material';
//
import { grey } from '@mui/material/colors';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  actions?: ReactNode;
  beforeAction?: ReactNode;
  heading: string;
  subHeading?: string;
  afterHeading?: ReactNode;
  moreLink?: string | string[];
  actionsDisplay?: 'inline' | 'bottom-inline';
  stackWrapperLinkActionProps?: StackProps;
}

export default function HeaderBreadcrumbs({
  links = [],
  actions,
  beforeAction,
  heading,
  afterHeading,
  moreLink = '',
  sx,
  subHeading,
  actionsDisplay = 'inline',
  stackWrapperLinkActionProps,
  ...other
}: Props) {
  return (
    <Stack sx={{ mb: 2, ...sx, mt: 2 }}>
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        {beforeAction && <Box sx={{ flexShrink: 0 }}>{beforeAction}</Box>}
        <Stack flex={1}>
          <Stack alignItems={'center'} direction={'row'} spacing={5}  {...stackWrapperLinkActionProps}>
            <Stack direction={'row'} alignItems={'center'} spacing={1}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography noWrap variant="h4">
                  {heading}
                </Typography>
                {afterHeading && <>{afterHeading}</>}
              </Stack>
              {links.length > 0 && <Breadcrumbs links={links} {...other} />}
            </Stack>

            {actions && actionsDisplay === 'inline' && (
              <Box sx={{ flex: 1 }}>{actions}</Box>
            )}
          </Stack>

          {subHeading && (
            <Stack>
              <Typography fontSize={16} color={grey[700]}>
                {subHeading}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>

      {typeof moreLink === 'string' ? (
        <Box sx={{ mt: 2 }}>
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        </Box>
      ) : (
        moreLink.map((href) => (
          <Box sx={{ mt: 2 }} key={href}>
            <Link
              noWrap
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          </Box>
        ))
      )}

      {actions && actionsDisplay === 'bottom-inline' && (
        <Box sx={{ flex: 1 }}>{actions}</Box>
      )}
    </Stack>
  );
}

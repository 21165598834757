import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type GuidSettingSliceType = {
  isShow: boolean
}

export type GuideSliceType = {
  salaryInformation: GuidSettingSliceType
}

// initial data
const initialState: GuideSliceType = {
  salaryInformation: {
    isShow: true
  }
}

export type NameGuide = 'salaryInformation'

// slice
const slice = createSlice({
  name: 'guideSlice',
  initialState,
  reducers: {
    changeIsShowGuide(
      state,
      action: PayloadAction<{
        nameGuide: NameGuide
        isShow: boolean
      }>
    ) {
      state[action.payload.nameGuide].isShow = action.payload.isShow
    }
  }
})

//Reducer
export default slice.reducer

//Action
export const { changeIsShowGuide } = slice.actions

import { ConsultantCallKpi } from './consultant-call-kpi';
import { Experience } from './experience';
import { Industry } from './industry';
import { ScheduleClass } from './scheduleClass';
import { User } from './user';

//-------------------------------------------
//Class enum
export enum ClassTypeType {
  ONLINE = 'Học trực tuyến',
  OFFLINE = 'Học tại nhà',
}

export enum ClassStatusType {
  INACTIVE = 'Chưa kích hoạt',
  ACTIVE = 'Kích hoạt',
  PENDING_CONFIRM = 'Đang chờ xác nhận',
  PENDING_APPLICATION = 'Đang chờ tuyển chọn',
  PENDING_PAYMENT = 'Đang chờ thanh toán',
  CANCELLED_PAYMENT = 'Đã hủy thanh toán',
  IN_PROCESS = 'Đang trong quá trình',
  COMPLETED = 'Đã hoàn thành',
  CANCELLED = 'Đã hủy lớp học',
  APPLICATION_DEADLINE_PASSED = 'Đã hết hạn tuyển chọn',
  REJECT = 'Từ chối',
}

export enum ClassCheckResultType {
  SUCCESS = 'Thành công',
  FAIL = 'Thất bại',
}

export enum ClassPaymentStatusType {
  PENDING = 'Chờ xử lý',
  CONFIRMED = 'Xác nhận',
  FIRST_PAYMENT_CONFIRMED = 'Xác nhận đợt 1',
  LAST_PAYMENT_CONFIRMED = 'Xác nhận đợt 2',
  CANCELED = 'Hủy',
  EXPIRED = 'Hết hạn',
  LAST_EXPIRED = 'Hết hạn đợt 2',

  CANCELED_BY_TEACHER = 'Hủy do người dạy',
}

export enum ClassPaymentType {
  FULL_PAYMENT = 'Trả hết',
  STAGED_PAYMENT = 'Trả theo đợt',
}

//-------------------------------------------
//Service
export type SearchInfinityClassesRequest = {
  searchCharacter?: string;
  limit?: number;
  page: number;
  type?: 'teacher' | 'student'; 
};

export type SearchInfinityClassesProposalRequest = {
  searchCharacter?: string;
  limit?: number;
  page: number;
  type: ClassTypeType,
  id: number
};

export type GetClassRequest = {
  id: number;
};

export type ViewClassRequest = {
  classApplicationInformationId?: number;
};

export type UpdateCVData = {
  id: number;
  classCheckResultType: ClassCheckResultType;
  version: number;
  classFailReasons?: Partial<ClassFailReason>[];
};

//-------------------------------------------
//Class type
export type ClassTeacherRequirement = {
  id: number;
  highestLevel: string;
  description: string;
  genders: string[];
  ageForm: number;
  ageTo: number;
  price: number;
  classId: number;
  experienceId: number;
  experience: Experience;
};

export type ClassProposedSchedule = {
  id: number;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
  classId: number;
};

export type ClassFailReason = {
  id: number;
  reason: string;
  classParticipants?: User[];
  classParticipantIds?: number[];
};

export type ClassAddress = {
  id: number;
  lat: number;
  long: number;
  district: string;
  province: string;
  ward: string;
  address: string;
  classId: number;
};

export type ClassAdvancePayment = {
  id: number;
  classId: number;
  total: number;
  pricePerHour: number;
  pricePerHourWithSurcharge: number;
  isPaid: boolean;
  paymentDate: Date;
  paymentConfirmDate: Date;
};

export type ClassPayment = {
  id: number;
  classId: number;
  total: number;
  type: ClassPaymentType;
  pricePerHour: number;
  pricePerHourWithSurcharge: number;
  firstPaidWeek: number;
  lastPaidWeek: number;
  isPaid: boolean;
  firstPaymentAmount: number;
  lastPaymentAmount: number;
  firstPaymentDate: Date;
  firstConfirmPaymentDate: Date;
  lastPaymentDate: Date;
  status: ClassPaymentStatusType;
};

export type Class = {
  id: number;
  code: string;
  scheduleType: string;
  weekCount: number;
  type: ClassTypeType;
  status: ClassStatusType;
  applicationDeadlineAt: Date | string | number;
  startAt?: Date | string | number;
  endAt?: Date | string | number;
  version: number;
  ownerId: number;
  owner: User;
  teacherId?: number;
  teacher?: User;
  totalMaleParticipant: number;
  totalFemaleParticipant: number;
  totalLearnMinutes: number;
  classTeacherRequirement: ClassTeacherRequirement;
  classProposedSchedules: ClassProposedSchedule[];
  classParticipants: User[];
  industry: Industry;
  countTeacherApplications: number;
  createdAt: Date | string | number;
  classFailReasons: ClassFailReason[];
  classAddress: ClassAddress;
  classAdvancePayment?: ClassAdvancePayment;
  consultantCallKpis: ConsultantCallKpi[];
  classPayment: ClassPayment;
  semiEndAt?: Date | string | number;
  classTeacherApplications: any[]
  countClassTeacherApplication?: number
  scheduleClasses: ScheduleClass[]
};

export type ClassSum = {
  finishCount: number;
  teachingCount: number;
};

//Form
export type ClassFailReasonForm = {
  id: number;
  reason: string;
  classParticipantIds?: number[];
};

export type staticCountClassCreateRes = {
  countClassCreate: number;
  countClassCreatePrevious: number;
};

export type staticCountCheckedClassRes = {
  countCheckedClassCreate: number;
  countCheckedClassCreatePrevious: number;
};

export type staticDetailTimeCountCreatedCheckedClassRes = {
  countClassCreate: number;
  countCheckedClassCreate: number;
  date: Date;
}[];

export type staticCountTypeClassKpiRes = {
  countClassKpiReject: number,
  countClassKpiSuccess: number,
};

//------------------------------------
//Other
export type ClassSearchInfinityFilter = {
  character: string;
  type?: 'teacher' | 'student';
};

//-------------------------------------------
//Redux
export type VerificationMode = 'VERIFY' | 'VIEW';

export interface IClassState {
  selectedClassId: number | null;
  verification: {
    verifyDialog: {
      toggle: boolean;
    };
    mode: VerificationMode;
  };
}

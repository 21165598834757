// ----------------------------------------------------------------------

import { FrontendPrefixType } from 'src/constants/prefix';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

// --------- admin ------------------
const ROOTS_ADMIN = `/${FrontendPrefixType.ADMIN}`;
const ROOTS_ADMIN_AUTH = path(ROOTS_ADMIN, '/auth');
const ROOTS_ADMIN_INDUSTRY = path(ROOTS_ADMIN, '/nganh');
const ROOTS_ADMIN_LOCATION = path(ROOTS_ADMIN, '/vi-tri-dia-ly');
const ROOTS_ADMIN_TERM_POLICY = path(ROOTS_ADMIN, '/dieu-khoan-va-chinh-sach');
const ROOTS_ADMIN_USER_STUDENT = path(ROOTS_ADMIN, '/tai-khoan-nguoi-hoc');
const ROOTS_ADMIN_USER_TEACHER = path(ROOTS_ADMIN, '/tai-khoan-nguoi-day');
const ROOTS_ADMIN_USER_ADMIN = path(ROOTS_ADMIN, '/tai-khoan-admin');

// --------- consultant ------------------
const ROOTS_CONSULTANT = `/${FrontendPrefixType.CONSULTANT}`;
const ROOTS_CONSULTANT_AUTH = path(ROOTS_CONSULTANT, '/auth');

// --------- accountant ------------------
const ROOTS_ACCOUNTANT = `/${FrontendPrefixType.ACCOUNTANT}`;
const ROOTS_ACCOUNTANT_AUTH = path(ROOTS_ACCOUNTANT, '/auth');

// ----------------------------------------------------------------------
export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_ADMIN = {
  root: ROOTS_ADMIN,
  auth: {
    root: ROOTS_ADMIN_AUTH,
    login: path(ROOTS_ADMIN_AUTH, '/login'),
    register: path(ROOTS_ADMIN_AUTH, '/register'),
    loginUnprotected: path(ROOTS_ADMIN_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_ADMIN_AUTH, '/register-unprotected'),
    verify: path(ROOTS_ADMIN_AUTH, '/verify'),
    resetPassword: path(ROOTS_ADMIN_AUTH, '/reset-password'),
    newPassword: path(ROOTS_ADMIN_AUTH, '/new-password'),
  },
  industry: {
    root: ROOTS_ADMIN_INDUSTRY,
  },
  location: {
    root: ROOTS_ADMIN_LOCATION,
  },
  termPolicy: {
    root: ROOTS_ADMIN_TERM_POLICY,
    add: path(ROOTS_ADMIN_TERM_POLICY, '/tao-moi'),
    edit: (type: string) => path(ROOTS_ADMIN_TERM_POLICY, `/${type}/chinh-sua`),
  },
  user: {
    student: {
      root: ROOTS_ADMIN_USER_STUDENT,
      view: (id: number) => path(ROOTS_ADMIN_USER_STUDENT, `/${id}`),
    },
    teacher: {
      root: ROOTS_ADMIN_USER_TEACHER,
      view: (id: number) => path(ROOTS_ADMIN_USER_TEACHER, `/${id}`),
    },
    admin: {
      root: ROOTS_ADMIN_USER_ADMIN,
      view: (id: number) => path(ROOTS_ADMIN_USER_ADMIN, `/${id}`),
    },
  },
};

export const PATH_CONSULTANT = {
  root: ROOTS_CONSULTANT,
  auth: {
    root: ROOTS_CONSULTANT_AUTH,
    login: path(ROOTS_CONSULTANT_AUTH, '/login'),
    register: path(ROOTS_CONSULTANT_AUTH, '/register'),
    loginUnprotected: path(ROOTS_CONSULTANT_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_CONSULTANT_AUTH, '/register-unprotected'),
    verify: path(ROOTS_CONSULTANT_AUTH, '/verify'),
    resetPassword: path(ROOTS_CONSULTANT_AUTH, '/reset-password'),
    newPassword: path(ROOTS_CONSULTANT_AUTH, '/new-password'),
  },
  checkCv: path(ROOTS_CONSULTANT, '/kiem-duyet-cv'),
  manageCheckCv: path(ROOTS_CONSULTANT, '/quan-ly-kiem-duyet-cv'),
  checkClass: path(ROOTS_CONSULTANT, '/xet-duyet-lop'),
  manageCheckClass: path(ROOTS_CONSULTANT, '/quan-ly-xet-duyet-lop'),
  tuitionReminderOfStudent: path(ROOTS_CONSULTANT, '/nhac-nop-hoc-phi'),
  manageTuitionReminderOfStudent: path(ROOTS_CONSULTANT, '/quan-ly-nhac-nop-hoc-phi'),
  tuitionReminderOfTeacher: path(ROOTS_CONSULTANT, '/nhac-nop-le-phi'),
  manageTuitionReminderOfTeacher: path(ROOTS_CONSULTANT, '/quan-ly-nhac-nop-le-phi'),
  combine_classes: path(ROOTS_CONSULTANT, '/ghep-lop-hoc'),
  manage_combine_classes: path(ROOTS_CONSULTANT, '/quan-ly-ghep-lop-hoc'),
  combine_teaching_classes: path(ROOTS_CONSULTANT, '/ghep-lop-day'),
  manage_combine_teaching_classes: path(ROOTS_CONSULTANT, '/quan-ly-ghep-lop-day'),
  dashboard: path(ROOTS_CONSULTANT, '/dashboard'),
  workSchedule: path(ROOTS_CONSULTANT, '/lich-lam-viec'),
  taskHistory: path(ROOTS_CONSULTANT, '/lich-su-cong-viec'),
  salaryStatistics: path(ROOTS_CONSULTANT, '/thong-ke-luong'),
  compensations: path(ROOTS_CONSULTANT, '/danh-sach-boi-thuong'),
  trainingSection: path(ROOTS_CONSULTANT, '/muc-dao-tao'),
  manageStaff: path(ROOTS_CONSULTANT, '/quan-ly-nhan-vien'),
  accountManagement: path(ROOTS_CONSULTANT, '/quan-ly-tai-khoan'),
  staffTraining: path(ROOTS_CONSULTANT, '/quan-ly-nhan-vien/dao-tao-nhan-vien'),
  scheduleSetting: path(
    ROOTS_CONSULTANT,
    '/quan-ly-nhan-vien/cai-dat-lich-lam',
  ),
  trainingStaff: path(ROOTS_CONSULTANT, '/quan-ly-nhan-vien/dao-tao-nhan-vien'),
  detailTrainingStaff: (userId: number) =>
    path(ROOTS_CONSULTANT, `/quan-ly-nhan-vien/dao-tao-nhan-vien/${userId}`),
  staffsSetting: path(ROOTS_CONSULTANT, '/quan-ly-nhan-vien/cai-dat-nhan-vien'),
  staffSetting: (id: number) =>
    path(ROOTS_CONSULTANT, '/quan-ly-nhan-vien/cai-dat-nhan-vien/' + id),
  manageRecruitment: path(ROOTS_CONSULTANT, '/quan-ly-tuyen-dung'),
  newRecruitment: path(ROOTS_CONSULTANT, '/quan-ly-tuyen-dung/tin-tuyen-dung'),
  viewRecruitmentApplications: (recruitmentCode: string) =>
    path(
      ROOTS_CONSULTANT,
      '/quan-ly-tuyen-dung/tin-tuyen-dung/' + recruitmentCode,
    ),
  contract: path(ROOTS_CONSULTANT, '/quan-ly-tuyen-dung/hop-dong-lam-viec'),
  employeeAdvanceFee: path(ROOTS_CONSULTANT, '/quan-ly-tuyen-dung/tam-ung'),
  viewContractHandovers: (contractCode: string) =>
    path(
      ROOTS_CONSULTANT,
      '/quan-ly-tuyen-dung/hop-dong-lam-viec/' + contractCode,
    ),
  workingRules: path(ROOTS_CONSULTANT, '/quan-ly-tuyen-dung/quy-dinh-lam-viec'),
  reMarketing: path(ROOTS_CONSULTANT, '/tiep-thi-lai'),
  manageReMarketing: path(ROOTS_CONSULTANT, '/quan-ly-tiep-thi-lai'),
  studyReminder: path(ROOTS_CONSULTANT, '/nhac-nho-hoc'),
  manageStudyReminder: path(ROOTS_CONSULTANT, '/quan-ly-nhac-nho-hoc'),
  classRecommendation: path(ROOTS_CONSULTANT, '/de-xuat-lop'),
  manageClassRecommendation: path(ROOTS_CONSULTANT, '/quan-ly-de-xuat-lop'),
  teachReminder: path(ROOTS_CONSULTANT, '/nhac-nho-day'),
  manageTeachReminder: path(ROOTS_CONSULTANT, '/quan-ly-nhac-nho-day'),
  historySalary: path(ROOTS_CONSULTANT, '/lich-su-nhan-luong')
};

export const PATH_ACCOUNTANT = {
  root: ROOTS_ACCOUNTANT,
  auth: {
    root: ROOTS_CONSULTANT_AUTH,
    login: path(ROOTS_ACCOUNTANT_AUTH, '/login'),
    register: path(ROOTS_ACCOUNTANT_AUTH, '/register'),
    loginUnprotected: path(ROOTS_ACCOUNTANT_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_ACCOUNTANT_AUTH, '/register-unprotected'),
    verify: path(ROOTS_ACCOUNTANT_AUTH, '/verify'),
    resetPassword: path(ROOTS_ACCOUNTANT_AUTH, '/reset-password'),
    newPassword: path(ROOTS_ACCOUNTANT_AUTH, '/new-password'),
  },
  dashboard: path(ROOTS_ACCOUNTANT, '/dashboard'),
  //Income transaction
  depositRequest: path(ROOTS_ACCOUNTANT, '/yeu-cau-nap-tien'),
  depositSupport: path(ROOTS_ACCOUNTANT, '/tro-giup-nap-tien'),
  undefinedReceipt: path(ROOTS_ACCOUNTANT, '/nhan-chua-xac-dinh'),
  undefinedReceiptHistory: path(
    ROOTS_ACCOUNTANT,
    '/nhan-chua-xac-dinh/lich-su-xac-minh',
  ),
  incomeOverview: path(ROOTS_ACCOUNTANT, '/thong-ke-tong-thu'),
  reportDepositTransaction: path(ROOTS_ACCOUNTANT, '/bao-cao-nap-tien'),
  reportDepositTransactionHistory: path(
    ROOTS_ACCOUNTANT,
    '/bao-cao-nap-tien/lich-su-xac-minh',
  ),
  undefinedReceiptVerification: path(
    ROOTS_ACCOUNTANT,
    '/xac-minh-nhan-chua-xac-dinh',
  ),
  undefinedReceiptVerificationHistory: path(
    ROOTS_ACCOUNTANT,
    '/xac-minh-nhan-chua-xac-dinh/lich-su-xac-minh',
  ),
  undefinedReceiptDeletion: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xoa-nhan-chua-xac-dinh',
  ),
  undefinedReceiptDeletionHistory: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xoa-nhan-chua-xac-dinh/lich-su-xoa-ma',
  ),
  verifyIncomeCompanyWithdrawTransaction: path(
    ROOTS_ACCOUNTANT,
    '/thu-tien-duyet-tien-lay-ve',
  ),
  verifyIncomeCompanyWithdrawTransactionHistory: path(
    ROOTS_ACCOUNTANT,
    '/thu-tien-duyet-tien-lay-ve/lich-su-xac-minh',
  ),
  verifyRevenueMoneyHolderAccountVerification: path(
    ROOTS_ACCOUNTANT,
    '/duyet-thu-cuoi-ca',
  ),
  verifyRevenueCompensation: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xy-ly-boi-thuong-thu',
  ),
  verifyRevenueMoneyHolderAccountVerificationHistory: (id: number) =>
    path(ROOTS_ACCOUNTANT, '/duyet-thu-cuoi-ca/lich-su-xac-minh/' + id),
  //Expense transaction
  withdrawRequest: path(ROOTS_ACCOUNTANT, '/yeu-cau-rut-tien'),
  withdrawSupport: path(ROOTS_ACCOUNTANT, '/tro-giup-rut-tien'),
  salaryPayment: path(ROOTS_ACCOUNTANT, '/thanh-toan-luong'),
  undefinedRefund: path(ROOTS_ACCOUNTANT, '/hoan-chua-xac-dinh'),
  undefinedRefundHistory: path(
    ROOTS_ACCOUNTANT,
    '/hoan-chua-xac-dinh/lich-su-xac-minh',
  ),
  expenseOverview: path(ROOTS_ACCOUNTANT, '/thong-ke-tong-chi'),
  reportWithdrawTransaction: path(ROOTS_ACCOUNTANT, '/bao-cao-rut-tien'),
  reportWithdrawTransactionHistory: path(
    ROOTS_ACCOUNTANT,
    '/bao-cao-rut-tien/lich-su-xac-minh',
  ),
  undefinedRefundVerification: path(
    ROOTS_ACCOUNTANT,
    '/xac-minh-hoan-chua-xac-dinh',
  ),
  undefinedRefundVerificationHistory: path(
    ROOTS_ACCOUNTANT,
    '/xac-minh-hoan-chua-xac-dinh/lich-su-xac-minh',
  ),
  undefinedRefundDeletion: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xoa-hoan-chua-xac-dinh',
  ),
  undefinedRefundDeletionHistory: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xoa-hoan-chua-xac-dinh/lich-su-xoa-ma',
  ),
  verifyExpenseCompanyWithdrawTransaction: path(
    ROOTS_ACCOUNTANT,
    '/chi-tien-duyet-tien-lay-ve',
  ),
  verifyExpenseCompanyWithdrawTransactionHistory: path(
    ROOTS_ACCOUNTANT,
    '/chi-tien-duyet-tien-lay-ve/lich-su-xac-minh',
  ),
  verifyExpenseCompanyDepositTransaction: path(
    ROOTS_ACCOUNTANT,
    '/chi-tien-duyet-tien-them-vao',
  ),
  verifyExpenseCompanyDepositTransactionHistory: path(
    ROOTS_ACCOUNTANT,
    '/chi-tien-duyet-tien-them-vao/lich-su-xac-minh',
  ),
  verifyExpenseMoneyHolderAccountVerification: path(
    ROOTS_ACCOUNTANT,
    '/duyet-chi-cuoi-ca',
  ),
  verifyExpenseCompensation: path(
    ROOTS_ACCOUNTANT,
    '/duyet-xy-ly-boi-thuong-chi',
  ),
  verifyExpenseMoneyHolderAccountVerificationHistory: (id: number) =>
    path(ROOTS_ACCOUNTANT, '/duyet-chi-cuoi-ca/lich-su-xac-minh/' + id),
  //Common
  workSchedule: path(ROOTS_ACCOUNTANT, '/lich-lam-viec'),
  taskHistory: path(ROOTS_ACCOUNTANT, '/lich-su-cong-viec'),
  salaryStatistics: path(ROOTS_ACCOUNTANT, '/thong-ke-luong'),
  compensations: path(ROOTS_ACCOUNTANT, '/danh-sach-boi-thuong'),
  trainingSection: path(ROOTS_ACCOUNTANT, '/muc-dao-tao'),
  accountManagement: path(ROOTS_ACCOUNTANT, '/quan-ly-tai-khoan'),
  historySalary: path(ROOTS_ACCOUNTANT, '/lich-su-nhan-luong'),
  //Manager
  manageBank: path(ROOTS_ACCOUNTANT, '/quan-ly-ngan-hang'),
  manageBankCard: path(ROOTS_ACCOUNTANT, '/quan-ly-ngan-hang/the-ngan-hang'),
  manageRevenueBank: path(ROOTS_ACCOUNTANT, '/quan-ly-ngan-hang/ngan-hang-thu'),
  manageExpenseBank: path(ROOTS_ACCOUNTANT, '/quan-ly-ngan-hang/ngan-hang-chi'),
  manageInternalBank: path(
    ROOTS_ACCOUNTANT,
    '/quan-ly-ngan-hang/ngan-hang-noi-bo',
  ),
  manageStaff: path(ROOTS_ACCOUNTANT, '/quan-ly-nhan-vien'),
  manageStatistic: path(ROOTS_ACCOUNTANT, '/quan-ly-thong-ke'),
  manageRevenueStatistic: path(ROOTS_ACCOUNTANT, '/quan-ly-thong-ke/thu'),
  manageExpenseStatistic: path(ROOTS_ACCOUNTANT, '/quan-ly-thong-ke/chi'),
  staffTraining: path(ROOTS_ACCOUNTANT, '/quan-ly-nhan-vien/dao-tao-nhan-vien'),
  detailTrainingStaff: (userId: number) =>
    path(ROOTS_ACCOUNTANT, `/quan-ly-nhan-vien/dao-tao-nhan-vien/${userId}`),
  scheduleSetting: path(
    ROOTS_ACCOUNTANT,
    '/quan-ly-nhan-vien/cai-dat-lich-lam',
  ),
  staffsSetting: path(ROOTS_ACCOUNTANT, '/quan-ly-nhan-vien/cai-dat-nhan-vien'),
  staffSetting: (id: number) =>
    path(ROOTS_ACCOUNTANT, '/quan-ly-nhan-vien/cai-dat-nhan-vien/' + id),
  manageRecruitment: path(ROOTS_ACCOUNTANT, '/quan-ly-tuyen-dung'),
  newRecruitment: path(ROOTS_ACCOUNTANT, '/quan-ly-tuyen-dung/tin-tuyen-dung'),
  viewRecruitmentApplications: (recruitmentCode: string) =>
    path(
      ROOTS_ACCOUNTANT,
      '/quan-ly-tuyen-dung/tin-tuyen-dung/' + recruitmentCode,
    ),
  contract: path(ROOTS_ACCOUNTANT, '/quan-ly-tuyen-dung/hop-dong-lam-viec'),
  viewContractHandovers: (contractCode: string) =>
    path(
      ROOTS_ACCOUNTANT,
      '/quan-ly-tuyen-dung/hop-dong-lam-viec/' + contractCode,
    ),
  employeeAdvanceFee: path(ROOTS_ACCOUNTANT, '/quan-ly-tuyen-dung/tam-ung'),
  workingRules: path(ROOTS_ACCOUNTANT, '/quan-ly-tuyen-dung/quy-dinh-lam-viec'),
};

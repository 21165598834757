import { useQuery } from '@tanstack/react-query';
import { Pagination } from 'src/@types/pagination';
import { QUERY_KEYS } from 'src/constants/queryKey';
import { getConversationsToday } from 'src/services/consultant/conversation.consultant.service';

export function useGetAllConversationsToday(pagination: Pagination & {
  startTime?: string | Date
}) {
  return {
    ...useQuery({
      queryKey: [QUERY_KEYS.CONVERSATIONS, 'today'],
      queryFn: () => getConversationsToday(pagination),
      enabled: false,
    }),
  };
}

// routes
import { PATH_CONSULTANT } from '../../../routes/paths';
// components
import { ConsultantRoleType } from 'src/@types/user';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => {
  return (
    <SvgIconStyle
      src={`/assets/icons/navbar/${name}.svg`}
      sx={{ width: 1, height: 1 }}
    />
  );
};

const ICONS = {
  servicesPlumber: getIcon('services-plumber-icon'),
  archive: getIcon('archive-icon'),
  archiveLine: getIcon('archive-line-icon'),
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  book: getIcon('book-icon'),
  globe: getIcon('globe-icon'),
  publisher: getIcon('publisher-icon'),
  description: getIcon('description-icon'),
  pen: getIcon('pen-icon'),
  admin: getIcon('admin-icon'),
  termPolicy: getIcon('term-policy-icon'),
  customGraduationHat: getIcon('custom-graduation-hat'),
  customFileTick: getIcon('custom-file-tick'),
  checkClass: getIcon('check_class_icon'),
  bookTool: getIcon('book-tool-icon'),
  penTool1: getIcon('pen-tool-icon-1'),
  tuitionReminder: getIcon('tuition-fee'),
  combine_classes: getIcon('combine_classes'),
  combine_teaching_classes: getIcon('combine_teaching_classes'),
  reMarketing: getIcon('remarketing'),
  studyReminder: getIcon('study-reminder'),
  classRecommendation: getIcon('class-recommendation'),
  teachReminder: getIcon('teach-reminder'),
};

// STAFF
// ----------------------------------------------------------------------
const navConfig = [
  // STUDENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Người học',
    icon: ICONS.penTool1,
    items: [
      // Industry
      {
        title: 'Xét duyệt lớp',
        path: PATH_CONSULTANT.checkClass,
        icon: ICONS.checkClass,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Xét duyệt lớp',
        path: PATH_CONSULTANT.manageCheckClass,
        icon: ICONS.checkClass,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Nhắc học phí',
        path: PATH_CONSULTANT.tuitionReminderOfStudent,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Nhắc học phí',
        path: PATH_CONSULTANT.manageTuitionReminderOfStudent,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Tiếp thị lại',
        path: PATH_CONSULTANT.reMarketing,
        icon: ICONS.reMarketing,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Tiếp thị lại',
        path: PATH_CONSULTANT.manageReMarketing,
        icon: ICONS.reMarketing,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Ghép lớp học',
        path: PATH_CONSULTANT.combine_classes,
        icon: ICONS.combine_classes,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Ghép lớp học',
        path: PATH_CONSULTANT.manage_combine_classes,
        icon: ICONS.combine_classes,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Nhắc nhở học',
        path: PATH_CONSULTANT.studyReminder,
        icon: ICONS.studyReminder,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Nhắc nhở học',
        path: PATH_CONSULTANT.manageStudyReminder,
        icon: ICONS.studyReminder,
        roles: [ConsultantRoleType.MANAGER],
      },
    ],
  },
  // TEACHER
  // ----------------------------------------------------------------------
  {
    subheader: 'Người dạy',
    icon: ICONS.bookTool,
    items: [
      // Industry
      {
        title: 'Kiểm duyệt CV',
        path: PATH_CONSULTANT.checkCv,
        icon: ICONS.customFileTick,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Kiểm duyệt CV',
        path: PATH_CONSULTANT.manageCheckCv,
        icon: ICONS.customFileTick,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Nhắc lệ phí',
        path: PATH_CONSULTANT.tuitionReminderOfTeacher,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Nhắc lệ phí',
        path: PATH_CONSULTANT.manageTuitionReminderOfTeacher,
        icon: ICONS.tuitionReminder,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Đề xuất lớp',
        path: PATH_CONSULTANT.classRecommendation,
        icon: ICONS.classRecommendation,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Đề xuất lớp',
        path: PATH_CONSULTANT.manageClassRecommendation,
        icon: ICONS.classRecommendation,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Ghép lớp dạy',
        path: PATH_CONSULTANT.combine_teaching_classes,
        icon: ICONS.combine_teaching_classes,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Ghép lớp dạy',
        path: PATH_CONSULTANT.manage_combine_teaching_classes,
        icon: ICONS.combine_teaching_classes,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Nhắc nhở dạy',
        path: PATH_CONSULTANT.teachReminder,
        icon: ICONS.teachReminder,
        roles: [ConsultantRoleType.STAFF],
      },
      {
        title: 'Nhắc nhở dạy',
        path: PATH_CONSULTANT.manageTeachReminder,
        icon: ICONS.teachReminder,
        roles: [ConsultantRoleType.MANAGER],
      },
    ],
  },
];

export default navConfig;

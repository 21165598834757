// @mui
// components
import { SettingsValueProps } from './components/settings/type';
// routes
import { S3ClientConfig } from '@aws-sdk/client-s3';
import { PATH_ACCOUNTANT, PATH_ADMIN, PATH_CONSULTANT } from './routes/paths';

// API
// ----------------------------------------------------------------------
export const ADMIN_DOMAIN = process.env.REACT_APP_ADMIN_DOMAIN || '';
export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_SOCKET_IO = process.env.REACT_APP_HOST_SOCKET_IO_KEY || '';
export const NOTIFICATION_HOST_API =
  process.env.REACT_APP_NOTIFICATION_HOST_API_KEY || '';
export const NOTIFICATION_HOST_SOCKET_IO =
  process.env.REACT_APP_NOTIFICATION_HOST_SOCKET_IO_KEY || '';
export const GET_PRIVATE_IMAGE_API = (id?: number | null): string => {
  if (!id) return '';
  return HOST_API + `/files/get-private-url/${id}`;
};

export const JWT = {
  PUBLIC_TOKEN_SECRET_KEY: process.env.REACT_JWT_PUBLIC_TOKEN_SECRET_KEY || '',
  PUBLIC_TOKEN_EXPIRES_IN: process.env.REACT_JWT_PUBLIC_TOKEN_EXPIRES_IN || '',
};

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const GOOGLE_API = {
  googleMapKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  mapURL: process.env.REACT_APP_GOOGLE_MAP_API,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_ADMIN_LOGIN = PATH_ADMIN.industry.root; // as '/admin/industry'
export const PATH_AFTER_CONSULTANT_LOGIN = PATH_CONSULTANT.workSchedule; // as '/tu-van/kiem-duyet-class'
export const PATH_AFTER_CONSULTANT_MANAGER_LOGIN = PATH_CONSULTANT.scheduleSetting; // as '/tu-van/kiem-duyet-class
export const PATH_AFTER_ACCOUNTANT_LOGIN = PATH_ACCOUNTANT.workSchedule; // as '/ke-toan/yeu-cau-nap-tien
export const PATH_AFTER_ACCOUNTANT_MANAGER_LOGIN =
  PATH_ACCOUNTANT.scheduleSetting; // as '/ke-toan/yeu-cau-nap-tien

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 60,
  MAIN_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_HEIGHT: 60,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 60,
  AVATAR_SIZE: 35,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 260,
  DASHBOARD_COLLAPSE_WIDTH: 70,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const DIALOG = {
  MIN_WIDTH: 1070,
  MIN_WIDTH_SMALL: 700,
};

export const AUTH = {
  TUTORIAL_WIDTH: 700,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
  NAVBAR_HEADER_ITEM: 32,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// config s3
export const S3 = {
  endPointUrl: process.env.REACT_APP_S3_ENDPOINT_URL,
  endPointUrlShow: process.env.REACT_APP_S3_ENDPOINT_URL_SHOW,
  endPoint: process.env.REACT_APP_S3_ENDPOINT,
  accessKey: process.env.REACT_APP_S3_ACCESS_KEY,
  secretKey: process.env.REACT_APP_S3_SECRET_KEY,
  bucketNames: {
    userAvatar: process.env.REACT_APP_S3_BUCKET_NAME_USER_AVATAR,
    userId: process.env.REACT_APP_S3_BUCKET_NAME_USER_ID,
    userVideo: process.env.REACT_APP_S3_BUCKET_NAME_USER_VIDEO,
    userDegree: process.env.REACT_APP_S3_BUCKET_NAME_USER_DEGREE,
    userConversation: process.env.REACT_APP_S3_BUCKET_NAME_USER_CONVERSATION,
    companyTransaction:
      process.env.REACT_APP_S3_BUCKET_NAME_COMPANY_TRANSACTION,
    moneyHolder: process.env.REACT_APP_S3_BUCKET_NAME_MONEY_HOLDER,
    contract: process.env.REACT_APP_S3_BUCKET_NAME_CONTRACT,
    recruitmentApplication:
      process.env.REACT_APP_S3_BUCKET_NAME_RECRUITMENT_APPLICATION,
    moneyHolderAccountVerification:
      process.env.REACT_APP_S3_BUCKET_NAME_MONEY_HOLDER_ACCOUNT_VERIFICATION,
    userEditorImage: process.env.REACT_APP_S3_BUCKET_NAME_USER_EDITOR_IMAGE,
  },
};

export const s3Config: S3ClientConfig = {
  region: 'ap-south-1',
  credentials: {
    accessKeyId: `${S3.accessKey}`,
    secretAccessKey: `${S3.secretKey}`,
  },
  endpoint: `${S3.endPointUrl}`,
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardSideType } from 'src/@types/dashboard';
import { TransactionCompensationLogState } from 'src/@types/transaction-compensation-log';

const initialState: TransactionCompensationLogState = {
  default: {
    create: {
      dialog: {
        toggle: false,
      },
    },
    view: {
      dialog: {
        toggle: false,
      },
    },
  },
  manager: {
    verify: {
      filter: {
        searchCharacter: '',
      },
      dialog: {
        toggle: false,
      },
    },
  },
};

export const transactionCompensationLogSlice = createSlice({
  name: 'transactionCompensationLog',
  initialState,
  reducers: {
    //Common
    setSelectedTransactionCompensationLogId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.selectedTransactionCompensationLogId = action.payload;
    },
    //Default
    setCreateDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['default']['create']['dialog']
      >,
    ) => {
      state.default.create.dialog = {
        ...state.default.create.dialog,
        ...action.payload,
      };
    },
    resetCreateDialog: (state) => {
      state.default.create.dialog = initialState.default.create.dialog;
    },

    setViewDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['default']['view']['dialog']
      >,
    ) => {
      state.default.view.dialog = {
        ...state.default.view.dialog,
        ...action.payload,
      };
    },
    resetViewDialog: (state) => {
      state.default.view.dialog = initialState.default.view.dialog;
    },
    //Manager
    setManagerVerifyDialog: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['manager']['verify']['dialog']
      >,
    ) => {
      state.manager.verify.dialog = {
        ...state.manager.verify.dialog,
        ...action.payload,
      };
    },
    resetManagerVerifyDialog: (state) => {
      state.manager.verify.dialog = initialState.manager.verify.dialog;
    },
    setManagerVerifyFilter: (
      state,
      action: PayloadAction<
        TransactionCompensationLogState['manager']['verify']['filter']
      >,
    ) => {
      state.manager.verify.filter = {
        ...state.manager.verify.filter,
        ...action.payload,
      };
    },
    setManagerVerifyDashboardSide: (
      state,
      action: PayloadAction<DashboardSideType | undefined>,
    ) => {
      state.manager.verify.dashboardSide = action.payload;
    },
  },
});

export default transactionCompensationLogSlice.reducer;

export const {
  setCreateDialog,
  resetCreateDialog,
  setSelectedTransactionCompensationLogId,
  resetManagerVerifyDialog,
  setManagerVerifyDialog,
  setManagerVerifyFilter,
  setManagerVerifyDashboardSide,
  resetViewDialog,
  setViewDialog,
} = transactionCompensationLogSlice.actions;

import { Box, Stack, StackProps, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { m } from 'framer-motion';
import { customIcon } from 'src/assets';
import { formatDate, momentVi } from 'src/utils/date-handle';
import MyAvatar from '../MyAvatar';
import { IconSVG } from '../icon';
import NotificationMenu from './NotificationMenu';

interface NotificationProps extends StackProps {
  colorTitle: string;
  content: string;
  time: Date;
  handle: () => void;
  avatarUrl?: string;
  fullName?: string;
  alt?: string;
  handleRemove: () => void;
  handleMark: () => void;
  isMark?: boolean;
}

export default function Notification({
  colorTitle,
  content,
  time,
  handle,
  avatarUrl,
  alt,
  handleRemove,
  handleMark,
  isMark = false,
  ...other
}: NotificationProps) {
  const theme = useTheme();

  const date = momentVi(time);
  const currentDate = momentVi();

  const contentsWhenHasAlt = alt ? content?.split(alt) : [];

  return (
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Stack
        style={{
          cursor: 'pointer',
        }}
        alignItems={'center'}
        padding={theme.spacing(2, 1, 1, 3)}
        direction={'row'}
        maxWidth={'425px'}
        width={'100%'}
        position={'relative'}
        minHeight={'90px'}
        {...other}
      >
        <Stack onClick={handle} spacing={2.5} direction={'row'} flex={1}>
          <Box position={'relative'}>
            {avatarUrl || alt ? (
              <MyAvatar
                style={{ width: 40, height: 40 }}
                src={avatarUrl}
                alt={alt}
              />
            ) : (
              <Stack
                alignItems={'center'}
                borderRadius={100}
                bgcolor={'#F0D800'}
                minWidth={'40px'}
                height={'40px'}
              >
                <IconSVG
                  path={customIcon.logo}
                  css={{ color: 'white', width: 30, height: 40 }}
                />
              </Stack>
            )}
          </Box>
          <Box flex={1}>
            {!(alt && contentsWhenHasAlt?.length > 1) ? (
              <Typography color={grey[700]} fontWeight={400} pr={2.5}>
                {content}
              </Typography>
            ) : (
              <Typography color={grey[700]} fontWeight={400} pr={2.5}>
                {contentsWhenHasAlt[0]}{' '}
                <b
                  style={{
                    color: theme.palette.common.black,
                  }}
                >
                  {alt}
                </b>{' '}
                {contentsWhenHasAlt[1]}
              </Typography>
            )}
            <Stack spacing={1} direction={'row'}>
              <Typography color={colorTitle} fontWeight={500} variant="caption">
                {date.isSameOrBefore(currentDate, 'day') &&
                date.isSameOrAfter(currentDate, 'day')
                  ? `${formatDate(time, 'HH:mm:ss')} Hôm nay`
                  : formatDate(time, 'HH:mm:ss DD-MM-YYYY')}
              </Typography>
              {isMark && (
                <Stack
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={20}
                  height={20}
                >
                  <IconSVG
                    css={{
                      width: 11,
                      height: 20,
                      color: grey[500],
                      marginTop: -2,
                    }}
                    path={customIcon.pin}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
        <NotificationMenu
          handlePin={handleMark}
          isMark={isMark}
          handleRemove={handleRemove}
          style={{
            height: 40,
            width: 40,
            position: 'absolute',
            top: 0,
            right: 2,
          }}
        />
      </Stack>
    </m.div>
  );
}

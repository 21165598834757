import { FileData } from './file';

export enum MoneyHolderAccountTypeType {
  REVENUE = 'Thu',
  EXPENSE = 'Chi',
  INTERNAL = 'Nội bộ',
}

export type CompanyAccountMonthlyAuditHeader = {
  bankAccountId?: string | number | null;
  ewalletAccountId?: string | number | null;
};

export type AssignMultiStaffAccountantRequest = {
  staffAccountantId: number;
  ewalletAccountIds: number[];
  bankAccountIds: number[];
  moneyHolderAccountType: MoneyHolderAccountTypeType;
};

export type CreateCreditFormProps = {
  moneyHolderName: string;
  accountNumber: string;
  ownerName: string;
  qrCodeImage: Partial<FileData> | null;
  companyAccountType: MoneyHolderAccountTypeType;
  isActive: boolean;
  initialBalance: number;
};

export type UpdateCreditFormProps = CreateCreditFormProps;

import { AccountantRoleType } from 'src/@types/user';
import Iconify from 'src/components/Iconify';
import Image from 'src/components/Image';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { setVerifyBeforeEndDialog } from 'src/redux/slices/header.slice';
import { dispatch } from 'src/redux/store';
import { PATH_ACCOUNTANT } from 'src/routes/paths';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/header/${name}`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  calendar: getIcon('calendar.svg'),
  chart: getIcon('chart.svg'),
  deadline: getIcon('deadline.svg'),
  timeCircle: getIcon('time-circle.svg'),
  manageStaff: getIcon('manage-staff.svg'),
  manageRecruitment: getIcon('manage-recruitment.svg'),
  newRecruitment: getIcon('new-recruitment.svg'),
  scheduleSettings: getIcon('schedule-setting.svg'),
  trainingOnline: getIcon('training-online.svg'),
  userSettings: getIcon('user-settings.svg'),
  contract: getIcon('contract.svg'),
  bank: (
    <Image
      src="/assets/icons/header/bank.png"
      sx={{
        width: 20,
      }}
    />
  ),
  bankCard: <Iconify icon={'wpf:bank-cards'} fontSize={18} />,
  revenueBank: (
    <Image
      src="/assets/icons/header/revenue-bank.png"
      sx={{
        width: 20,
      }}
    />
  ),
  expenseBank: (
    <Image
      src="/assets/icons/header/expense-bank.png"
      sx={{
        width: 20,
      }}
    />
  ),
  revenueStatisticManager: (
    <Image
      src="/assets/icons/header/revenue-statistic-manager.png"
      sx={{
        width: 20,
      }}
    />
  ),
  expenseStatisticManager: (
    <Image
      src="/assets/icons/header/expense-statistic-manager.png"
      sx={{
        width: 20,
      }}
    />
  ),
  moneyHand: getIcon('money-hand.svg'),
  workingRule: getIcon('working-rule.svg'),
  statisticManager: getIcon('statistic-manager.svg'),
  boardIcon: getIcon('board-icon.svg'),
  billReceiptIcon: getIcon('bill-receipt-icon.svg'),
};

const headerMenuConfig = [
  {
    title: 'Lịch làm việc',
    path: PATH_ACCOUNTANT.workSchedule,
    icon: ICONS.calendar,
    roles: [AccountantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Lịch sử công việc',
    path: PATH_ACCOUNTANT.taskHistory,
    icon: ICONS.timeCircle,
    roles: [AccountantRoleType.STAFF],
  },
  {
    title: 'Thống kê lương',
    path: PATH_ACCOUNTANT.salaryStatistics,
    icon: ICONS.chart,
    roles: [AccountantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Xác minh cuối ca',
    path: '#',
    icon: ICONS.deadline,
    roles: [AccountantRoleType.STAFF],
    onClick: () => {
      dispatch(
        setVerifyBeforeEndDialog({
          open: true,
        }),
      );
    },
  },
  {
    title: 'Mục đào tạo',
    path: PATH_ACCOUNTANT.trainingSection,
    icon: ICONS.boardIcon,
    roles: [AccountantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Quản lý ngân hàng',
    path: PATH_ACCOUNTANT.manageBank,
    icon: ICONS.bank,
    roles: [AccountantRoleType.MANAGER],
    items: [
      {
        title: 'Thẻ & Ngân hàng',
        path: PATH_ACCOUNTANT.manageBankCard,
        icon: ICONS.bankCard,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Ngân hàng thu tiền',
        path: PATH_ACCOUNTANT.manageRevenueBank,
        icon: ICONS.revenueBank,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Ngân hàng chi tiền',
        path: PATH_ACCOUNTANT.manageExpenseBank,
        icon: ICONS.expenseBank,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Ngân hàng nội bộ',
        path: PATH_ACCOUNTANT.manageInternalBank,
        icon: ICONS.expenseBank,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
  {
    title: 'Quản lý thống kê',
    path: PATH_ACCOUNTANT.manageStatistic,
    icon: ICONS.statisticManager,
    roles: [AccountantRoleType.MANAGER],
    items: [
      {
        title: 'Thống kê tổng thu',
        path: PATH_ACCOUNTANT.manageRevenueStatistic,
        icon: ICONS.revenueStatisticManager,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Thống kê tổng chi',
        path: PATH_ACCOUNTANT.manageExpenseStatistic,
        icon: ICONS.expenseStatisticManager,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
  {
    title: 'Quản lý nhân viên',
    path: PATH_ACCOUNTANT.manageStaff,
    icon: ICONS.manageStaff,
    roles: [AccountantRoleType.MANAGER],
    items: [
      {
        title: 'Đào tạo nhân viên',
        path: PATH_ACCOUNTANT.staffTraining,
        icon: ICONS.trainingOnline,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Cài đặt lịch làm',
        path: PATH_ACCOUNTANT.scheduleSetting,
        icon: ICONS.scheduleSettings,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Cài đặt nhân viên',
        path: PATH_ACCOUNTANT.staffsSetting,
        icon: ICONS.userSettings,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
  {
    title: 'Quản lý tuyển dụng',
    path: PATH_ACCOUNTANT.manageRecruitment,
    icon: ICONS.manageRecruitment,
    roles: [AccountantRoleType.MANAGER],
    items: [
      {
        title: 'Tin tuyển dụng',
        path: PATH_ACCOUNTANT.newRecruitment,
        icon: ICONS.newRecruitment,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Hợp đồng làm việc',
        path: PATH_ACCOUNTANT.contract,
        icon: ICONS.contract,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Tạm ứng',
        path: PATH_ACCOUNTANT.employeeAdvanceFee,
        icon: ICONS.billReceiptIcon,
        roles: [AccountantRoleType.MANAGER],
      },
      {
        title: 'Quy định làm việc',
        path: PATH_ACCOUNTANT.workingRules,
        icon: ICONS.workingRule,
        roles: [AccountantRoleType.MANAGER],
      },
    ],
  },
];

export default headerMenuConfig;

import { ConsultantRoleType } from 'src/@types/user';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { PATH_CONSULTANT } from 'src/routes/paths';

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/header/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  calendar: getIcon('calendar'),
  chart: getIcon('chart'),
  deadline: getIcon('deadline'),
  timeCircle: getIcon('time-circle'),
  manageStaff: getIcon('manage-staff'),
  scheduleSettings: getIcon('schedule-setting'),
  trainingOnline: getIcon('training-online'),
  userSettings: getIcon('user-settings'),
  manageRecruitment: getIcon('manage-recruitment'),
  newRecruitment: getIcon('new-recruitment'),
  contract: getIcon('contract'),
  moneyHand: getIcon('money-hand'),
  billReceiptIcon: getIcon('bill-receipt-icon'),
  workingRule: getIcon('working-rule'),
  boardIcon: getIcon('board-icon'),
};

const headerMenuConfig = [
  {
    title: 'Lịch làm việc',
    path: PATH_CONSULTANT.workSchedule,
    icon: ICONS.calendar,
    roles: [ConsultantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Lịch sử công việc',
    path: PATH_CONSULTANT.taskHistory,
    icon: ICONS.timeCircle,
    roles: [ConsultantRoleType.STAFF],
  },
  {
    title: 'Thống kê lương',
    path: PATH_CONSULTANT.salaryStatistics,
    icon: ICONS.chart,
    roles: [ConsultantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Mục đào tạo',
    path: PATH_CONSULTANT.trainingSection,
    icon: ICONS.boardIcon,
    roles: [ConsultantRoleType.STAFF],
    isOutWorkTime: true,
  },
  {
    title: 'Quản lý nhân viên',
    path: PATH_CONSULTANT.manageStaff,
    icon: ICONS.manageStaff,
    roles: [ConsultantRoleType.MANAGER],
    items: [
      {
        title: 'Đào tạo nhân viên',
        path: PATH_CONSULTANT.staffTraining,
        icon: ICONS.trainingOnline,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Cài đặt lịch làm',
        path: PATH_CONSULTANT.scheduleSetting,
        icon: ICONS.scheduleSettings,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Cài đặt nhân viên',
        path: PATH_CONSULTANT.staffsSetting,
        icon: ICONS.userSettings,
        roles: [ConsultantRoleType.MANAGER],
      },
    ],
  },
  {
    title: 'Quản lý tuyển dụng',
    path: PATH_CONSULTANT.manageRecruitment,
    icon: ICONS.manageRecruitment,
    roles: [ConsultantRoleType.MANAGER],
    items: [
      {
        title: 'Tin tuyển dụng',
        path: PATH_CONSULTANT.newRecruitment,
        icon: ICONS.newRecruitment,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Hợp đồng làm việc',
        path: PATH_CONSULTANT.contract,
        icon: ICONS.contract,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Tạm ứng',
        path: PATH_CONSULTANT.employeeAdvanceFee,
        icon: ICONS.billReceiptIcon,
        roles: [ConsultantRoleType.MANAGER],
      },
      {
        title: 'Quy định làm việc',
        path: PATH_CONSULTANT.workingRules,
        icon: ICONS.workingRule,
        roles: [ConsultantRoleType.MANAGER],
      },
    ],
  },
];

export default headerMenuConfig;

import { PrefixType } from 'src/constants/prefix';
import { CompanyBankAccount } from './company-bank-account';
import { CompanyBankAccountAssignment } from './company-bank-account-assignment';
import { CompanyEwalletAccount } from './company-e-wallet-account';
import { CompanyEwalletAccountAssignment } from './company-e-wallet-account-assignment';
import { ConsultantCallKpi } from './consultant-call-kpi';
import { FileData } from './file';
import { Identification } from './identification';
import { RatingSummary } from './rating-summary';
import { RequestContractHandover } from './request-contract-handover';
import { TransactionOverview } from './transaction-overview';
import { Wallet } from './wallet';
import { Class } from './class';

//----------------------------------------------------------------
//State
export interface IUserState {
  user: User | null;
}

// ----------------------------------------------------------------------
export enum ProfileGenderEnum {
  MALE = 'male',
  FEMALE = 'female',
  OTHER = 'other',
}

const genderOptionsMap: Record<string, ProfileGenderEnum> = {
  Nam: ProfileGenderEnum.MALE,
  Nữ: ProfileGenderEnum.FEMALE,
  Khác: ProfileGenderEnum.OTHER,
};

export const genderOptions = Object.keys(genderOptionsMap).map((key) => ({
  label: key,
  value: genderOptionsMap[key],
}));

export enum UserStatusType {
  ACTIVE = 'Kích hoạt',
  INACTIVE = 'Chưa kích hoạt',
  PENDING = 'Đang chờ',
  SUSPENDED = 'Đã bị khóa tạm thời',
  BANNED = 'Đã bị khóa',
}

export enum UserType {
  STUDENT = 'Người học',
  TEACHER = 'Người dạy',
  ADMIN = 'Admin',
  CONSULTANT = 'Tư vấn',
  ACCOUNTANT = 'Kế toán',
  CLASS_PARTICIPANT = 'Học viên',
}

export enum AccountantRoleType {
  MANAGER = 'Quản lý',
  STAFF = 'Nhân viên',
}

export enum EmploymentType {
  PART_TIME = 'Bán thời gian',
  FULL_TIME = 'Toàn thời gian',
}

export enum AccountantEmploymentType {
  PART_TIME = 'Bán thời gian',
  FULL_TIME = 'Toàn thời gian',
}

export enum ConsultantRoleType {
  MANAGER = 'Quản lý',
  STAFF = 'Nhân viên',
}

export enum ConsultantEmploymentType {
  PART_TIME = 'Bán thời gian',
  FULL_TIME = 'Toàn thời gian',
}

export type Profile = {
  avatar?: FileData;
  avatarCircle: FileData;
  fullName: string;
  id: number;
  dateOfBirth: string;
  gender: string;
  avatarId: number;
  ownerId: number;
  highestLevel: string;
  school: string;
};

export type AccountSetting = {
  id: number;
  isReceiveNotification: boolean;
  isAnonymous: boolean;
};

export type User = {
  id: number;
  status: UserStatusType;
  admin?: Admin;
  student?: Student;
  teacher?: Teacher;
  consultant?: Consultant;
  accountant?: Accountant;
  classParticipant?: ClassParticipant;
  profile: Profile;
  identification: Identification;
  accountSetting: AccountSetting;
  wallet: Wallet;
  createdAt: Date;
  ratingSummaries: RatingSummary[];
  rating?: RatingSummary;
  isOnline?: boolean;
  lastOnlineAt: Date;
  companyBankAccountAssignments: CompanyBankAccountAssignment[];
  assignedCompanyBankAccountAssignments: CompanyBankAccountAssignment[];
  companyEwalletAccountAssignments: CompanyEwalletAccountAssignment[];
  assignedCompanyEwalletAccountAssignments: CompanyEwalletAccountAssignment[];
  companyBankAccounts: CompanyBankAccount[];
  companyEwalletAccounts: CompanyEwalletAccount[];
  transactionOverview?: TransactionOverview;
  requestContractHandover?: RequestContractHandover;
  classTeacherApplicationsCount?: number;
  consultantCallKpiReceivers: ConsultantCallKpi[];
  classTeacherApplications: any[]
  class?: Class
};

export type Admin = {
  id: number;
  email: string;
  createdAt: string;
};

export type Student = {
  id: number;
  email: string;
  phone: string;
  code: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  isManual: boolean;
  createdAt: string;
};

export type Teacher = {
  id: number;
  email: string;
  phone: string;
  code: string;
  emailVerified: boolean;
  phoneVerified: boolean;
  isManual: boolean;
  createdAt: string;
};

export type Consultant = {
  id: number;
  email: string;
  companyEmail: string;
  phone: string;
  code: string;
  createdAt: string;
  trainingAt?: Date;
  employmentType: ConsultantEmploymentType;
  role: ConsultantRoleType;
  user: User;
  userId: number;
  expiredContractHandoverAt?: Date;
  isOfficial: boolean;
  layOffAt?: Date;
};

export type Accountant = {
  id: number;
  email: string;
  companyEmail: string;
  phone: string;
  code: string;
  createdAt: string;
  trainingAt?: Date;
  employmentType: AccountantEmploymentType;
  role: AccountantRoleType;
  user: User;
  expiredContractHandoverAt?: Date;
  userId: number;
  isOfficial: boolean;
  layOffAt?: Date;
};

export type ClassParticipant = {
  id: number;
  email: string;
  phone: string;
  code: string;
  createdAt: string | Date | number;
};

export type UserFilter = {
  character: string;
  type?: UserType | null;
};

export type FindOneToCheckTransactionReq = {
  isGetPhone?: boolean;
};

export type SearchStaffReq = {
  page?: number;
  limit?: number;
  searchCharacter?: string;
  isValidContract?: boolean;
  isOfficial?: boolean;
  isGetCountVerifiedTrainingSection?: boolean;
  prefix: PrefixType.ACCOUNTANT | PrefixType.CONSULTANT;
  trainingSectionStatuses: string;
};

export type StaffUserSearchRes = User & {
  countVerifiedTrainingSection: number;
};

export type StaticOfficialRes = {
  countOfficials: number;
  countNotOfficials: number;
};

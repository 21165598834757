import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import { getUserType, isValidToken, setSession } from '../utils/jwt';
// @types
import {
  EmploymentType,
  TokenType,
  UserRoleType,
  UserTypeType,
} from 'src/@types/auth';
import { UserType } from 'src/@types/user';
import { ADMIN_DOMAIN } from 'src/config';
import { FrontendPrefixType } from 'src/constants/prefix';
import { getCurrentUser as getCurrentAccountant } from 'src/services/accountant/auth.accountant.service';
import { getCurrentUser as getCurrentAdmin } from 'src/services/admin/auth.admin.service';
import { getCurrentUser as getCurrentConsultant } from 'src/services/consultant/auth.consultant.service';
import axios from 'src/utils/axios';
import {
  ActionMap,
  AuthState,
  AuthUser,
  JWTContextType,
  LoginData,
} from '../@types/auth.admin';

// ----------------------------------------------------------------------
enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
}

// ----------------------------------------------------------------------
type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
    userType: UserTypeType;
    userRole: UserRoleType;
    employmentType: EmploymentType;
    isSignInByManager?: boolean;
    isOfficial?: boolean;
  };
  [Types.Login]: {
    user: AuthUser;
    userType: UserTypeType;
    userRole: UserRoleType;
    employmentType: EmploymentType;
    isSignInByManager?: boolean;
    isOfficial?: boolean;
  };
  [Types.Logout]: undefined;
};

export type JWTActions =
  ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userType: null,
  userRole: null,
  employmentType: null,
  isSignInByManager: false,
  isOfficial: false,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        userType: action.payload.userType,
        userRole: action.payload.userRole,
        employmentType: action.payload.employmentType,
        isSignInByManager: action.payload.isSignInByManager,
        isOfficial: action.payload.isOfficial,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        userType: action.payload.userType,
        userRole: action.payload.userRole,
        employmentType: action.payload.employmentType,
        isSignInByManager: action.payload.isSignInByManager,
        isOfficial: action.payload.isOfficial,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split('/');
  const currentPrefix = pathSegments[1];

  useEffect(() => {
    window.addEventListener('message', (event: any) => {
      if (event.origin !== ADMIN_DOMAIN) return;

      if (event.data.windowName && !window.name) {
        window.name = event.data.windowName;
      }
    });
  }, []);

  useEffect(() => {
    const initialize = async () => {
      try {
        let tokenKey: string = '';

        if (currentPrefix === FrontendPrefixType.ADMIN)
          tokenKey = TokenType.ADMIN_ACCESS_TOKEN;
        if (currentPrefix === FrontendPrefixType.CONSULTANT)
          tokenKey = TokenType.CONSULTANT_ACCESS_TOKEN;
        if (currentPrefix === FrontendPrefixType.ACCOUNTANT)
          tokenKey = TokenType.ACCOUNTANT_ACCESS_TOKEN;

        if (tokenKey) {
          let currentAccessToken;
          let isSignInByManager = false;

          //For manager sign in staff account
          if (window.name && window.name !== 'undefined') {
            const data = JSON.parse(window.name);

            if (data.accessToken) {
              currentAccessToken = data.accessToken;

              axios.defaults.headers.common.Authorization = `Bearer ${currentAccessToken}`;

              isSignInByManager = true;
            }
          }
          //Default
          else {
            currentAccessToken = localStorage.getItem(tokenKey);
          }

          if (
            currentAccessToken &&
            isValidToken(currentAccessToken) &&
            getUserType(currentAccessToken)
          ) {
            const userType = getUserType(currentAccessToken);
            let result: any;

            switch (userType) {
              case UserType.ADMIN:
                if (!window.name || window.name === 'undefined') {
                  setSession(currentAccessToken, TokenType.ADMIN_ACCESS_TOKEN);
                }
                result = await getCurrentAdmin();
                break;
              case UserType.CONSULTANT:
                if (!window.name || window.name === 'undefined') {
                  setSession(
                    currentAccessToken,
                    TokenType.CONSULTANT_ACCESS_TOKEN,
                  );
                }
                result = await getCurrentConsultant();
                break;
              case UserType.ACCOUNTANT:
                if (!window.name || window.name === 'undefined') {
                  setSession(
                    currentAccessToken,
                    TokenType.ACCOUNTANT_ACCESS_TOKEN,
                  );
                }
                result = await getCurrentAccountant();
                break;
              default:
                result = null;
            }

            const user = result?.user || null;

            const useRole = result?.userRole || null;
            const employmentType = result?.employmentType || null;

            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user: user,
                userType: result.userType,
                userRole: useRole,
                employmentType,
                isSignInByManager,
                isOfficial: result.isOfficial || false,
              },
            });
          } else {
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: false,
                user: null,
                userType: null,
                userRole: null,
                employmentType: null,
                isOfficial: false,
              },
            });
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              userType: null,
              userRole: null,
              employmentType: null,
              isOfficial: false,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            userType: null,
            userRole: null,
            employmentType: null,
          },
        });
      }
    };

    initialize();
  }, [window.name]);

  const login = (data: LoginData) => {
    const {
      accessToken,
      user,
      userType,
      userRole,
      employmentType = null,
      isOfficial,
    } = data;
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const currentPrefix = pathSegments[1];

    if (
      userType === UserType.ADMIN &&
      currentPrefix === FrontendPrefixType.ADMIN
    )
      setSession(accessToken, TokenType.ADMIN_ACCESS_TOKEN);

    if (
      userType === UserType.CONSULTANT &&
      currentPrefix === FrontendPrefixType.CONSULTANT
    )
      setSession(accessToken, TokenType.CONSULTANT_ACCESS_TOKEN);

    if (
      userType === UserType.ACCOUNTANT &&
      currentPrefix === FrontendPrefixType.ACCOUNTANT
    )
      setSession(accessToken, TokenType.ACCOUNTANT_ACCESS_TOKEN);

    dispatch({
      type: Types.Login,
      payload: {
        user,
        userType: userType,
        userRole: userRole,
        employmentType: employmentType,
        isOfficial,
      },
    });
  };

  const logout = () => {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/');
    const currentPrefix = pathSegments[1];

    if (currentPrefix === FrontendPrefixType.ADMIN)
      setSession(null, TokenType.ADMIN_ACCESS_TOKEN);

    if (currentPrefix === FrontendPrefixType.CONSULTANT)
      setSession(null, TokenType.CONSULTANT_ACCESS_TOKEN);

    if (currentPrefix === FrontendPrefixType.ACCOUNTANT)
      setSession(null, TokenType.ACCOUNTANT_ACCESS_TOKEN);

    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
